import {ButtonHTMLAttributes, FC} from 'react';
import styled from 'styled-components';
import {blockscoutColor} from 'src/views/blockscout/colors';

const StyledButton = styled.button<{themeColor: string; active?: boolean}>(
  ({themeColor, active}) => `
  display: flex;
  text-align: center;
  line-height: 36px;
  width: fit-content;
  border-radius: 2px;
  background: ${active ? themeColor : blockscoutColor.white};
  color: ${active ? blockscoutColor.white : themeColor};
  border: 1px solid ${themeColor};
  outline: none;
  padding: 0 15px;
  transition: all .4s ease-in-out;
  font-size: 12px;
  font-family: Nunito, Arial, sans-serif;
  cursor: pointer;

  :hover {
    background-color: ${themeColor};
    color: ${blockscoutColor.white};
  }
`,
);

export const BlockscoutButton: FC<
  ButtonHTMLAttributes<HTMLButtonElement> & {title: string; active?: boolean; themeColor: string}
> = ({title, themeColor, active, ...props}) => (
  <StyledButton themeColor={themeColor} active={active} {...props}>
    {title}
  </StyledButton>
);
