import {FC, useEffect, useRef, useState} from 'react';
import {RoundButton} from 'src/views/home/styled';
import styled from 'styled-components';

const StyledButton = styled.div`
  ${RoundButton};
  cursor: pointer;
`;

export const ViewMoreButton: FC<{viewAll: boolean; setViewAll: (val: boolean) => void}> = ({
  viewAll,
  setViewAll,
}) => {
  const [viewAllBtnClicked, setViewAllBtnClicked] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const onViewHandler = () => {
    setViewAll(!viewAll);
    setViewAllBtnClicked(true);
  };

  useEffect(() => {
    if (!viewAll && viewAllBtnClicked) {
      ref.current?.scrollIntoView({block: 'center'});
    }
  }, [viewAll, viewAllBtnClicked]);
  return (
    <StyledButton ref={ref} style={{margin: '10rem auto 0'}} onClick={onViewHandler}>
      {viewAll ? 'View less' : 'View more'}
    </StyledButton>
  );
};
