import {BigNumber} from 'bignumber.js';
import {BN} from './bignumber';
import {Q112} from '../constants/staticValues';

function getAtomic(amount: BigNumber.Value, decimals = 18): string {
  return BN(amount).pow(BN(10).pow(decimals)).toFixed(0);
}

function getDisplay(
  amount: BigNumber.Value,
  decimals = 18,
  precision: null | number = null,
): string {
  if (BN(amount).eq(0)) return '0.00';
  const val = BN(amount).div(BN(10).pow(decimals));
  if (BN(val).gte(100)) return numberWithSpaces(BN(amount).dp(0, 6).valueOf());
  if (precision) {
    let formattedVal = val.toFixed(precision);
    let count = 1;
    while (BN(formattedVal).eq(0)) {
      formattedVal = val.toFixed(precision + count);
      count += 2;
    }
    formattedVal = numberWithSpaces(formattedVal);
    if (formattedVal.match(/\./)) {
      return formattedVal.replace(/\.?0+$/, '');
    }
    return formattedVal;
  }
  if (BN(amount).eq(0)) return `${val}.00`;
  return val.toString();
}

function numberWithSpaces(x: string) {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
}

function getDisplayFromQ112(
  amount: BigNumber.Value,
  decimals = 18,
  precision: null | number = null,
): string {
  const amountFromQ112 = BN(amount).div(Q112);
  return getDisplay(amountFromQ112, decimals, precision);
}

function getShortEntry(amount: BigNumber.Value): string {
  if (BN(amount).gte(1_000_000)) {
    return `${BN(amount).div(1_000_000).toFixed(2)}M`;
  }
  if (BN(amount).gte(1_000)) {
    return `${BN(amount).div(1_000).toFixed(2)}K`;
  }
  if (amount >= 1) {
    return BN(amount).toFixed(2);
  }

  const y = amount.toString();
  const dotIndex = y.indexOf('.');
  if (dotIndex !== -1) {
    const a = y.substr(0, y.indexOf('.'));
    const b = y.substr(y.indexOf('.'));
    return a.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + b;
  }
  return y.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const formatNumber = {
  getAtomic,
  getDisplay,
  getShortEntry,
  getDisplayFromQ112,
};
