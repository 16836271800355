import styled from 'styled-components';
import {blockscoutColor} from 'src/views/blockscout/colors';

export const Container = styled.section`
  width: 100%;
  height: 100%;
  padding: 48px 15px 0;
  margin: 0 auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1200px) {
    max-width: 1300px;
  }
`;

export const Wrap = styled.div`
  min-width: 100%;
  font-family: Nunito, Arial, sans-serif;
  padding: 30px;
  background-color: ${blockscoutColor.white};
`;

export const Card = styled.div`
  background-color: ${blockscoutColor.white};
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 30px 0 rgb(202 199 226 / 50%);
  margin-bottom: 50px;
  overflow: hidden;
`;

export const Row = styled.div`
  margin: 0 -15px;
  display: flex;
  margin-bottom: 12px;
  color: ${blockscoutColor.blackText};
  font-size: 14px;

  @media screen and (max-width: 576px) {
    flex-wrap: wrap;
  }
`;

export const Term = styled.span`
  color: ${blockscoutColor.gray};
  font-size: 14px;
  line-height: 21px;
  padding: 0 15px;
  width: 100%;
  max-width: 16.66666667%;

  @media screen and (max-width: 900px) {
    max-width: 25%;
  }
  @media screen and (max-width: 505px) {
    max-width: 100%;
  }
`;

export const RightBlock = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: 5px 15px;
  align-items: center;
  padding: 0 15px;
  max-width: 83.33333333%;

  @media screen and (max-width: 991px) {
    max-width: 75%;
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 576px) {
    max-width: 100%;
  }
`;

export const StyledTitle = styled.h3`
  font-size: 18px;
  line-height: 1.2em;
  font-weight: 400;
  color: ${blockscoutColor.blackText};
  margin: 0 0 32px;
  white-space: nowrap;
`;

export const Divider = styled.hr`
  border: none;
  border-top: 1px solid ${blockscoutColor.grayBg};
  margin: 30px 0;
`;

export const PaginationWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 24px;
  margin-bottom: 30px;
`;

export const QuorumInfoWrap = styled(RightBlock)`
  @media screen and (min-width: 992px) {
    grid-template-columns: 470px 1fr;
  }
`;

export const StyledIcon = styled.div<{selected?: boolean; disabled?: boolean}>(
  ({selected, disabled}) => `
  display: grid;
  grid-gap: 5px;
  justify-items: center;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  background: ${selected ? blockscoutColor.accentedText : blockscoutColor.white};
  width: 100%;
  height: 100%;
  min-width: 120px;
  color: ${selected ? blockscoutColor.white : blockscoutColor.accentedText};
  border: 1px solid currentColor;
  font-size: 12px;
  font-weight: bold;
  opacity: ${disabled ? 0.5 : 1};

  :hover {
    background: ${disabled ? blockscoutColor.white : blockscoutColor.accentedText};
    color: ${disabled ? blockscoutColor.accentedText : blockscoutColor.white};
  }
  svg {
    width: auto;
    max-width: 100%;
    height: 40px;
    fill: transparent;
  }
`,
);

export const IconsContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
  overflow: scroll;
  padding-bottom: 16px;
  scrollbar-height: 0;
  scrollbar-width: 0;

  ::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
`;

export const NetworkName = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 12px;
  margin-left: 12px;
  color: #333;
  font-size: 14px;
  font-family: Nunito, Arial, sans-serif;
  cursor: default;
  :before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 6px;
    height: 6px;
    background: #80d6a1;
    border-radius: 50%;
  }
  @media screen and (max-width: 991px) {
    margin-left: 0;
  }
`;
