import {FC} from 'react';
import styled from 'styled-components';
import {blockscoutColor} from 'src/views/blockscout/colors';
import {ReactComponent as ArrowLeftIcon} from 'src/assets/icons/blockscout-arrow-left.svg';

const PaginationWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  background-color: ${blockscoutColor.grayBg};
  border: 1px solid ${blockscoutColor.grayBg};
  border-radius: 2px;
  color: ${blockscoutColor.grayText};
  fill: ${blockscoutColor.grayText};
  cursor: pointer;
  font-size: 12px;
  height: 24px;
  padding: 0 8px;
  :hover {
    background-color: ${blockscoutColor.main};
    border: 1px solid ${blockscoutColor.main};
    fill: ${blockscoutColor.white};
    color: ${blockscoutColor.white};
  }
  :disabled {
    opacity: 0.4;
    cursor: unset;
    background-color: ${blockscoutColor.grayBg};
    fill: ${blockscoutColor.grayText};
    border-color: ${blockscoutColor.grayBg};
    color: ${blockscoutColor.grayText};
  }
`;

export const BlockscoutPagination: FC<{
  currentPage: number;
  maxPages: number;
  setCurrentPage: (index: number) => void;
}> = ({currentPage, maxPages, setCurrentPage}) => (
  <PaginationWrapper>
    {currentPage > 1 && <StyledButton onClick={() => setCurrentPage(1)}>First</StyledButton>}
    <StyledButton disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>
      <ArrowLeftIcon />
    </StyledButton>
    <StyledButton disabled style={{opacity: 1}}>
      Page {currentPage}
    </StyledButton>
    <StyledButton
      disabled={currentPage === maxPages}
      onClick={() => setCurrentPage(currentPage + 1)}
    >
      <ArrowLeftIcon style={{transform: 'rotate(180deg)'}} />
    </StyledButton>
  </PaginationWrapper>
);
