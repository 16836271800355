import {FC} from 'react';
import {IntersectionBlock} from 'src/components/IntersectionBlock';
import {StyledSubtitle, StyledText, StyledTitle} from '../styled';

const observerConfig = {rootMargin: '0px 0px -75% 0px'};

export const Examples: FC<{
  refs: React.RefObject<HTMLDivElement>[];
  setCurrentSection: (index: number) => void;
}> = ({refs, setCurrentSection}) => (
  <>
    <IntersectionBlock
      onIntersecting={() => setCurrentSection(0)}
      intersectingRef={refs[0]}
      observerConfig={observerConfig}
    >
      <StyledTitle>Solidity</StyledTitle>
    </IntersectionBlock>
    <StyledText>
      To use oracle’s prices in your smartcontract interface ILegacyMetadataOracleV0_1 should be
      used.
    </StyledText>
    <pre>
      <code>
        {`// SPDX-License-Identifier: MIT
pragma solidity ^0.8.0;

import "./interfaces/legacy/ILegacyMetadataOracleV0_1.sol";

contract DataConsumerLegacy {
    
  ILegacyMetadataOracleV0_1 internal oracle;

  /**
   * network: Gnosis
   * oracle address: 0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA
   */
  constructor() {
      oracle = ILegacyMetadataOracleV0_1(0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA);
  }

  /**
   * Returns prices of 'ETH' and 'UNI-V2 WETH-CRV' in USD
   */
  function getLatestPrices() public view returns (uint[2] memory) {
      address[] memory assets = new address[](2);
      assets[0] = 0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2;
      assets[1] = 0x3dA1313aE46132A397D90d95B1424A9A7e3e0fCE;

      ILegacyMetadataOracleV0_1.Quote[] memory values = oracle.quoteAssets(assets);

      return [
          values[0].price / 2**112,
          values[1].price / 2**112
      ];
  }
}`}
      </code>
    </pre>

    <IntersectionBlock
      onIntersecting={() => setCurrentSection(1)}
      intersectingRef={refs[1]}
      observerConfig={observerConfig}
    >
      <StyledTitle>JavaScript</StyledTitle>
    </IntersectionBlock>
    <StyledSubtitle>web3</StyledSubtitle>
    <StyledText>
      This example uses web3.js to fetch prices of ETH and UNI-V2 WETH-CRV in Gnosis chain.
    </StyledText>
    <pre>
      <code>
        {`const Web3 = require("web3")
  const ORACLE_ADDR = '0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA';
  const RPC_URL = "https://rpc.gnosischain.com";
  const ASSETS = [
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    '0x3dA1313aE46132A397D90d95B1424A9A7e3e0fCE',
  ]
  const oracleReaderABI = [{"inputs": [{"internalType": "address[]","name": "assets","type": "address[]"}],"name": "quoteAssets","outputs": [{"components": [{"internalType": "uint256","name": "price","type": "uint256"},{"internalType": "uint32","name": "updateTS","type": "uint32"}],"internalType": "struct ILegacyMetadataOracleV0_1.Quote[]","name": "quotes","type": "tuple[]"}],"stateMutability": "view","type": "function"}]

  const web3 = new Web3(RPC_URL)
  const oracle = new web3.eth.Contract(oracleReaderABI, ORACLE_ADDR)
  oracle.methods.quoteAssets(ASSETS)
    .call()
    .then((prices) => {
      // handle code
      console.log("Latest prices", prices)
    })`}
      </code>
    </pre>
    <StyledSubtitle>ethers.js</StyledSubtitle>
    <StyledText>
      This example uses ethers.js to fetch prices of ETH and UNI-V2 WETH-CRV in Gnosis chain.
    </StyledText>
    <pre>
      <code>
        {`const { ethers } = require("ethers")
  const ORACLE_ADDR = '0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA';
  const RPC_URL = "https://rpc.gnosischain.com";
  const ASSETS = [
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    '0x3dA1313aE46132A397D90d95B1424A9A7e3e0fCE',
  ]
  const oracleReaderABI = [{"inputs": [{"internalType": "address[]","name": "assets","type": "address[]"}],"name": "quoteAssets","outputs": [{"components": [{"internalType": "uint256","name": "price","type": "uint256"},{"internalType": "uint32","name": "updateTS","type": "uint32"}],"internalType": "struct ILegacyMetadataOracleV0_1.Quote[]","name": "quotes","type": "tuple[]"}],"stateMutability": "view","type": "function"}]

  const provider = new ethers.providers.JsonRpcProvider(RPC_URL)
  const oracle  = new ethers.Contract(ORACLE_ADDR, oracleReaderABI, provider)
  oracle.quoteAssets(ASSETS)
    .then((prices) => {
      // handle code
      console.log("Latest prices", prices)
    })
`}
      </code>
    </pre>

    <IntersectionBlock
      onIntersecting={() => setCurrentSection(2)}
      intersectingRef={refs[2]}
      observerConfig={observerConfig}
    >
      <StyledTitle>Python</StyledTitle>
    </IntersectionBlock>
    <StyledText>
      This example uses web3.py to fetch prices of ETH and UNI-V2 WETH-CRV in Gnosis chain.
    </StyledText>
    <pre>
      <code>
        {`from web3 import Web3

ORACLE_ADDR = '0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA'
RPC_URL = "https://rpc.gnosischain.com"
ASSETS = [
  '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  '0x3dA1313aE46132A397D90d95B1424A9A7e3e0fCE',
]
ORACLE_READER_ABI = [{"inputs": [{"internalType": "address[]","name": "assets","type": "address[]"}],"name": "quoteAssets","outputs": [{"components": [{"internalType": "uint256","name": "price","type": "uint256"},{"internalType": "uint32","name": "updateTS","type": "uint32"}],"internalType": "struct ILegacyMetadataOracleV0_1.Quote[]","name": "quotes","type": "tuple[]"}],"stateMutability": "view","type": "function"}]


web3 = Web3(Web3.HTTPProvider(RPC_URL))
contract = web3.eth.contract(address=ORACLE_ADDR, abi=ORACLE_READER_ABI)
latestPrices = contract.functions.quoteAssets(ASSETS).call()
print(latestPrices)`}
      </code>
    </pre>
  </>
);
