import styled from 'styled-components';
import {breakpoint, color} from 'src/components/UI/theme';

export const ScrollButton = styled.div`
  position: fixed;
  bottom: 5rem;
  right: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  background: ${color.lightGray};
  border-radius: 50%;
  cursor: pointer;
  color: ${color.grayText};
  opacity: 0.8;

  svg {
    width: 3rem;
    height: 3rem;
    padding-top: 1rem;
    transform: rotate(180deg);
  }

  @media (min-width: ${breakpoint.desktop}) {
    display: none;
  }
`;
