import styled from 'styled-components';
import {breakpoint} from '../components/UI/theme';

export const MainWrap = styled.div`
  max-width: 1440px;
  padding: 0 5rem;
  width: 100%;
  margin: 0 auto;
  @media screen and (min-width: ${breakpoint.hd}) {
    max-width: 1800px;
  }
  @media (max-width: ${breakpoint.tablet}) {
    padding: 0 1.5rem;
  }
`;
