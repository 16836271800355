import {Link} from 'react-router-dom';
import {HeaderWrapper, LinksWrapper} from 'src/layout/Header/styled';
import {ReactComponent as Logo} from 'src/assets/logo.svg';
import {Menu} from 'src/layout/Header/Menu';
import {LINKS} from 'src/constants/staticValues';
import {ReactComponent as GithubIcon} from 'src/assets/icons/ic-github.svg';

export const Header = () => (
  <HeaderWrapper>
    <Link to={'/'}>
      <Logo />
    </Link>
    <LinksWrapper>
      <a href={LINKS.sdk} target="_blank" rel="noopener noreferrer">
        SDK
      </a>
      <a href={LINKS.docs} target="_blank" rel="noopener noreferrer">
        Docs
      </a>
      <a href={LINKS.explorer} target="_blank" rel="noopener noreferrer">
        Explorer
      </a>
      <a href={LINKS.github} target="_blank" rel="noopener noreferrer">
        <GithubIcon />
      </a>
      <Menu />
    </LinksWrapper>
  </HeaderWrapper>
);
