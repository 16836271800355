import styled from 'styled-components';
import {breakpoint, color, fontFamily} from 'src/components/UI/theme';

export const MainBlock = styled.div`
  width: auto;
  margin-bottom: 4rem;
  padding: 3rem 1rem;
  font-size: 2.25rem;
  background: ${color.white};
  border-radius: 1.25rem;
  flex-grow: 1;
  overflow: hidden;

  a {
    color: inherit;
  }

  pre {
    overflow: scroll;
    background: ${color.lightGray};
    padding: 2rem;
    border-radius: 0.5rem;
    font-size: 2rem;
    color: ${color.blackText};
  }

  code {
    background: ${color.lightGray};
    word-break: break-word;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style-position: inside;
    padding: 0;
  }

  table {
    display: block;
    width: 100%;
    overflow: auto;
    code {
      word-break: keep-all;
    }
  }

  th,
  td {
    border: 0.1rem solid rgba(0, 0, 0, 0.3);
    color: ${color.blackText};
    font-size: 2.25rem;
    text-align: left;
  }

  @media (min-width: ${breakpoint.desktop}) {
    padding: 2rem 4rem;
  }
`;

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  @media (min-width: ${breakpoint.desktop}) {
    flex-direction: row;
  }
`;

export const Menu = styled(MainBlock)`
  position: sticky;
  top: 4rem;
  height: auto;
  overflow: scroll;
  background: ${(p) => p.theme.mainBlockBg};
  padding: 2rem 0;
  margin-bottom: 0;
  font-size: 2.5rem;
  scrollbar-width: none;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    padding: 1rem;
    cursor: pointer;
    margin: 0;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${breakpoint.desktop}) {
    margin-right: 2rem;
    padding: 2rem;
    height: calc(100vh - 4rem);
  }
`;

export const MainBlockTitle = styled.h1`
  color: ${color.black};
  font-size: 3rem;
  margin: 0 0 4rem;
  width: 100%;
  text-align: left;
  display: block;
  @media screen and (min-width: ${breakpoint.tablet}) {
    font-size: 4rem;
    margin: 4rem 0;
  }
  @media screen and (min-width: ${breakpoint.desktop}) {
    font-size: 4.3rem;
    text-align: center;
  }
`;

export const MenuContainer = styled.div`
  flex-shrink: 0;
  width: 100%;
  @media (min-width: ${breakpoint.desktop}) {
    width: 30%;
  }
`;

export const StyledText = styled.p`
  margin: 0;
  font-size: 2.25rem;
  padding: 1rem 0;
  line-height: 1.5;
  color: ${color.blackText};
`;

export const StyledListItem = styled.li`
  padding: 0.5rem 0;
  color: ${color.blackText};
  font-size: 2.25rem;
  line-height: 1.5;
  @media screen and (min-width: ${breakpoint.desktop}) {
    padding: 0.5rem 0 0.5rem 1.8rem;
  }
`;

export const StyledTitle = styled.h2`
  margin-bottom: 0.6rem;
  font-size: 3rem;
  font-weight: 700;
  font-family: ${fontFamily.sans};
  letter-spacing: 0.01em;
`;

export const StyledSubtitle = styled.h4`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 2.5rem;
`;

export const StyledSmallSubtitle = styled.h5`
  font-size: 2.5rem;
  font-weight: 700;
  margin: 1rem 0;
  color: ${color.blackText};
`;

export const Card = styled('section')`
  padding: 2rem;
  background: ${color.lightGray};
  border-radius: 1.25rem;
  font-size: 2.25rem;
  line-height: 1.5;
`;
