import {useEffect, useState} from 'react';
import {BehaviorSubject} from 'rxjs';

export function useSubject<T>(subject: BehaviorSubject<T>): T {
  // if useSubject provided with initValue use it, else use subject value
  const [value, setValue] = useState<T>(subject.getValue());

  useEffect(() => {
    const subscription = subject.subscribe((newValue) => {
      setValue(newValue);
    });
    return () => subscription.unsubscribe();
  }, [subject]);

  return value;
}
