import {StyledListItem, StyledSubtitle, StyledText} from '../styled';

export const MainnetSchedule = () => (
  <>
    <StyledText>
      Even when all participants are honest, there is the tragedy of the commons (of some degree)
      regarding paying for mainnet transactions. A simple schedule may be suggested to address the
      issue.
    </StyledText>
    <StyledText>
      Let <code>T</code> be the time associated with some data, deterministic for all participants
      (e.g. <code>epochId</code> or the timestamp of the block when a quorum for the data was
      reached). Let <code>D</code> be an arbitrary mainnet-related duration, e.g. 1 minute for the
      Ethereum mainnet.
    </StyledText>
    <StyledText>
      Then, <code>i</code>-th participant must deliver a transaction to the mainnet during the time
      slot starting at <code>T + D * (uint(keccak256(data)) + i) % totalParticipants</code> of the
      length <code>D</code>. The absence of such a transaction can be easily proven by looking at
      the mainnet.
    </StyledText>

    <StyledSubtitle>Updating the oracle</StyledSubtitle>
    <ul>
      <StyledListItem>
        The protocol does not rely on strict participant clock synchronization - in the worst case
        signed data won’t match and multiparty signature won’t be produced.
      </StyledListItem>
      <StyledListItem>
        Moreover, it’s desirable to wait for some blocks after a stage beginning to avoid network
        reorganizations.
      </StyledListItem>
      <StyledListItem>
        The protocol relies on off-chain computations by the participants and doesn’t rely on
        on-chain computation. Again, no multiparty signature in the worst case. Such an approach has
        several benefits: small gas footprint (heavy computations are impossible even on side
        networks), simpler code, no side network state dependence.
      </StyledListItem>
    </ul>

    <StyledSubtitle>Participant set synchronization</StyledSubtitle>
    <StyledText>
      As an ultimate (and quite cheap) mitigation of the de-synchronization case, a new side network
      contract deployment may be suggested.
    </StyledText>
  </>
);
