import {ReactComponent as UniswapIcon} from 'src/assets/sources/uniswap.svg';
import {ReactComponent as BinanceIcon} from 'src/assets/sources/binance.svg';
import {ReactComponent as CompoundIcon} from 'src/assets/sources/compound.svg';
import {ReactComponent as HuobiIcon} from 'src/assets/sources/huobi.svg';
import {ReactComponent as KrakenIcon} from 'src/assets/sources/kraken.svg';
import {ReactComponent as CurveIcon} from 'src/assets/sources/curve.svg';
import {ReactComponent as GateioIcon} from 'src/assets/sources/gateio.svg';
import {ReactComponent as BitmartIcon} from 'src/assets/sources/bitmart.svg';
import {ReactComponent as CoinbaseIcon} from 'src/assets/sources/coinbase.svg';
import {ReactComponent as HotbitIcon} from 'src/assets/sources/hotbit.svg';
import {ReactComponent as MexcIcon} from 'src/assets/sources/mexc.svg';
import {ReactComponent as OkxIcon} from 'src/assets/sources/okx.svg';
import {ReactComponent as UpbitIcon} from 'src/assets/sources/upbit.svg';
import {ReactComponent as BigoneIcon} from 'src/assets/sources/bigone.svg';
import {ReactComponent as BitfinexIcon} from 'src/assets/sources/bitfinex.svg';
import {ReactComponent as BitgetIcon} from 'src/assets/sources/bitget.svg';
import {ReactComponent as BybitIcon} from 'src/assets/sources/bybit.svg';
import {ReactComponent as DigifinexIcon} from 'src/assets/sources/digifinex.svg';
import {ReactComponent as GeminiIcon} from 'src/assets/sources/gemini.svg';
import {ReactComponent as KucoinIcon} from 'src/assets/sources/kucoin.svg';
import {ReactComponent as LbankIcon} from 'src/assets/sources/lbank.svg';
import {ReactComponent as P2pb2bIcon} from 'src/assets/sources/p2pb2b.svg';
import {ReactComponent as WhitebitIcon} from 'src/assets/sources/whitebit.svg';
import {ReactComponent as XtcomIcon} from 'src/assets/sources/xtcom.svg';
import {ReactComponent as SushiIcon} from 'src/assets/sources/sushi.svg';
import {ReactComponent as TwelveIcon} from 'src/assets/sources/twelve.svg';

export const sourcesList = [
  {
    id: 'uniswap V2',
    icon: (
      <>
        <UniswapIcon style={{width: 'auto', marginRight: '2rem'}} />
        <span>Uniswap V2</span>
      </>
    ),
  },
  {
    id: 'uniswap V3',
    icon: (
      <>
        <UniswapIcon style={{width: 'auto', marginRight: '2rem'}} />
        <span>Uniswap V3</span>
      </>
    ),
  },
  {
    id: 'binance',
    icon: <BinanceIcon />,
  },
  {
    id: 'compound',
    icon: <CompoundIcon />,
  },
  {
    id: 'huobi',
    icon: <HuobiIcon />,
  },
  {
    id: 'kraken',
    icon: <KrakenIcon />,
  },
  {
    id: 'curve',
    icon: <CurveIcon />,
  },
  {
    id: 'gateio',
    icon: <GateioIcon />,
  },
  {
    id: 'bitmart',
    icon: <BitmartIcon />,
  },
  {
    id: 'coinbase',
    icon: <CoinbaseIcon />,
  },
  {
    id: 'hotbit',
    icon: <HotbitIcon />,
  },
  {
    id: 'mexc',
    icon: <MexcIcon />,
  },
  {
    id: 'okx',
    icon: <OkxIcon />,
  },
  {
    id: 'upbit',
    icon: <UpbitIcon />,
  },
  {
    id: 'bigone',
    icon: <BigoneIcon />,
  },
  {
    id: 'bitfinex',
    icon: <BitfinexIcon />,
  },
  {
    id: 'Bbitget',
    icon: <BitgetIcon />,
  },
  {
    id: 'bybit',
    icon: <BybitIcon />,
  },
  {
    id: 'digifinex',
    icon: <DigifinexIcon />,
  },
  {
    id: 'gemini',
    icon: <GeminiIcon />,
  },
  {
    id: 'kucoin',
    icon: <KucoinIcon />,
  },
  {
    id: 'lbank',
    icon: <LbankIcon />,
  },
  {
    id: 'p2pb2b',
    icon: (
      <>
        <P2pb2bIcon style={{width: 'auto', marginRight: '2rem'}} />
        <span>P2B</span>
      </>
    ),
  },
  {
    id: 'whitebit',
    icon: (
      <>
        <WhitebitIcon style={{width: 'auto', marginRight: '2rem'}} />
        <span>WhiteBIT</span>
      </>
    ),
  },
  {
    id: 'xtcomIcon',
    icon: <XtcomIcon />,
  },
  {
    id: 'SUSHI',
    icon: (
      <>
        <SushiIcon style={{width: 'auto', marginRight: '2rem'}} />
        <span>Sushi</span>
      </>
    ),
  }
];
