import {breakpoint, fontFamily} from 'src/components/UI/theme';
import {MainWrap} from 'src/layout/MainWrap';
import styled from 'styled-components';

export const StyledWrap = styled(MainWrap)`
  padding: 0;

  @media (min-width: ${breakpoint.desktop}) {
    padding: 15rem 0;
  }
`;

export const MainTitle = styled.h1`
  font-size: 6rem;
  font-family: ${fontFamily.sans};
  line-height: 1.25;

  @media (min-width: ${breakpoint.tablet}) {
    font-size: 8rem;
  }
`;
