import {StyledListItem, StyledSubtitle, StyledText, StyledTitle} from '../styled';

export const Intro = () => (
  <>
    <StyledTitle>Intro</StyledTitle>
    <StyledText>
      The connection of Chunk oracle to your smartcontract is quite easy. The examples below
      show how to get prices of <code>WETH</code> and <code>UNI-V2 WETH-CRV</code> and on Gnosis
      chain.
    </StyledText>

    <StyledTitle>Main concepts</StyledTitle>
    <StyledSubtitle>Proxies</StyledSubtitle>
    <StyledText>
      Development of contracts is continuing and a new version of the oracle contract could be
      deployed. To avoid changing the oracle contract address on each such deployment consumers can
      use the address of the proxy contract, which has the same interface{' '}
      <code>(ICoreMetadataOracleReader)</code> as the oracle contract.
    </StyledText>
    <StyledText>
      After the deployment of a new version of the oracle contract (and if the new version is
      backward compatible) implementation of the proxy is changed and migration for proxy users is
      seamless.
    </StyledText>
    <StyledSubtitle>
      Base <code style={{background: 'none'}}>2**112</code> for prices
    </StyledSubtitle>
    <StyledText>
      All prices are stored with base <code>2**112</code>. It allows to sure values less than zero.
    </StyledText>
    <ul>
      <StyledListItem>
        0.01 is stored as <code>0.01 * 2**112 = 51922968585348276285304963292200</code>
      </StyledListItem>
      <StyledListItem>
        100 is stored as <code>100 * 2**112 = 519229685853482762853049632922009600</code>
      </StyledListItem>
    </ul>
  </>
);
