import React from 'react';
import {SocialLinks} from 'src/layout/SocialLinks';
import {
  FooterContent,
  FooterWrap,
  InternalLink,
  ExternalLink,
  SocialLinksWrap,
} from 'src/layout/Footer/styled';
import {LINKS} from 'src/constants/staticValues';

export const Footer: React.FC = () => (
  <FooterWrap>
    <FooterContent>
      <ExternalLink href={LINKS.sdk} target="_blank" rel="noopener noreferrer">
        SDK
      </ExternalLink>
      <ExternalLink href={LINKS.docs} target="_blank" rel="noopener noreferrer">
        Docs
      </ExternalLink>
      <ExternalLink href={LINKS.explorer} target="_blank" rel="noopener noreferrer">
        Explorer
      </ExternalLink>
      <SocialLinksWrap>
        <SocialLinks />
      </SocialLinksWrap>
    </FooterContent>
  </FooterWrap>
);
