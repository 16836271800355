import React from 'react';
import {About} from 'src/views/home/components/About';
import {Discover} from 'src/views/home/components/Discover';
import {SupportedNetworks} from 'src/views/home/components/SupportedNetworks';
import {ReactComponent as CheckIcon} from 'src/assets/icons/circle-check.svg';
import {
  MetricsContainer,
  SectionWrap,
  StyledSubtitle,
  Container,
  DataItem,
} from 'src/views/home/styled';
import {Sources} from 'src/views/home/components/Sources';
import {Features} from 'src/views/home/components/Features';
import {Integration} from 'src/views/home/components/Integration';

const metrics = [
  'Token prices',
  'Stock and commodity prices',
  'Currency exchange rates',
  'Bond yields',
  'Macroeconomic indicators',
  'Sensor-generated time series',
];

export const Home: React.FC = () => (
  <Container>
    <About />
    <SectionWrap>
      <StyledSubtitle>Chunk provides data for over 1000 metrics</StyledSubtitle>
      <MetricsContainer>
        {metrics.map((item, index) => (
          <DataItem key={`${item}-${index}`}>
            <CheckIcon />
            {item}
          </DataItem>
        ))}
      </MetricsContainer>
    </SectionWrap>

    <SupportedNetworks />

    <Integration />

    <Features />

    <Sources />

    {/* <NumberSection>
      <div>
        <AccentedNumber>73 300</AccentedNumber>{' '}
        <b style={{paddingLeft: '1rem', fontFamily: fontFamily.sans}}>gas</b> <br />
        gas cost of one update
      </div>
      <div>
        <AccentedNumber>1000</AccentedNumber>
        <b
          style={{
            paddingLeft: '1rem',
            fontFamily: fontFamily.sans,
            fontSize: '6rem',
            verticalAlign: 'super',
          }}
        >
          +
        </b>{' '}
        <br />
        assets price feeds
      </div>
      <div>
        <AccentedNumber>0.2</AccentedNumber>{' '}
        <b style={{paddingLeft: '1rem', fontFamily: fontFamily.sans}}>%</b> <br />
        update precision value
      </div>
    </NumberSection> */}

    <Discover />
  </Container>
);
