import React from 'react';
import {
  RoundLinkExternal,
  StyledSectionWrap,
  StyledSubtitle,
  StyledText,
} from 'src/views/home/styled';
import {SubTitle} from 'src/views/home/components/Discover/styled';
import {LINKS} from 'src/constants/staticValues';

export const Discover = React.memo(() => (
  <>
    <StyledSectionWrap>
      <StyledSubtitle>
        Effortless Integration with <br /> Decentralized Oracle
      </StyledSubtitle>
      <StyledText>
        Chunk's decentralized oracle integrates seamlessly with any Ethereum Virtual Machine (EVM)
        compatible network. Explore the two possible usage scenarios:
      </StyledText>

      <SubTitle style={{paddingTop: '4rem'}}>In-network Data Utilization:</SubTitle>
      <StyledText style={{marginTop: '3rem'}}>
        Validators exchange technical messages on the Chunk network, and data writing takes
        place within the same network, utilizing the decentralized oracle mechanism for accurate and
        reliable data. Projects can easily access and use this data in their dApps through the
        provided SDK.
      </StyledText>

      <SubTitle style={{paddingTop: '4rem'}}>Cross-network Data Access:</SubTitle>
      <StyledText>
        Validators exchange technical messages on the Chunk network, while data writing occurs
        on any other EVM-compatible network, extending the decentralized oracle mechanism's
        financial data provision to external blockchains. Projects can use this data in their dApps
        by reading directly from contracts across networks. The Chainlink-compatible API makes
        switching to Chunk oracles virtually seamless.
      </StyledText>
    </StyledSectionWrap>
    <StyledSectionWrap>
      <StyledSubtitle>
        Embrace the Chunk Revolution: <br /> Start Today
      </StyledSubtitle>
      <StyledText>
        Join the expanding community of developers and innovators harnessing Chunk's power to
        build innovative decentralized applications. Dive into our documentation and resources to
        learn more and kickstart your journey.
      </StyledText>

      <RoundLinkExternal href={LINKS.docs} target="_blank" rel="noopener noreferrer">
        Learn more
      </RoundLinkExternal>
    </StyledSectionWrap>
  </>
));
