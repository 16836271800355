import {useState} from 'react';
import {SectionWrap, StyledSubtitle} from 'src/views/home/styled';

import {SourceCard, SourcesContainer} from 'src/views/home/components/Sources/styled';
import {ViewMoreButton} from 'src/views/home/components/ViewMoreButton';
import {sourcesList} from 'src/views/home/components/Sources/sourcesList';

export const Sources = () => {
  const [viewAll, setViewAll] = useState(false);

  const sources = viewAll ? sourcesList : sourcesList.slice(0, 8);

  return (
    <SectionWrap>
      <StyledSubtitle>Sources</StyledSubtitle>
      <SourcesContainer>
        {sources.map((source) => (
          <SourceCard key={source.id}>{source.icon}</SourceCard>
        ))}
      </SourcesContainer>
      <ViewMoreButton viewAll={viewAll} setViewAll={setViewAll} />
    </SectionWrap>
  );
};
