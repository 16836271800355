import {useMemo} from 'react';
import {CHAINS} from 'src/constants/chains';
import {useFeedVersion} from 'src/hooks/useFeedVersion';
import {useSubject} from 'src/hooks/useSubject';
import {assetsStore} from 'src/store/assetsStore';

export const useCurrentChainsList = () => {
  const version = useFeedVersion();
  const assets = useSubject(assetsStore.assets);
  const storeInitialized = useSubject(assetsStore.initialized);

  return useMemo(() => {
    if (!version || !assets || !storeInitialized) return;

    const networksBasedOnVersionAndAssets = Object.keys(assets[version] || {});
    return CHAINS.filter((chain) => networksBasedOnVersionAndAssets.includes(chain.id));
  }, [storeInitialized, version, assets]);
};
