import {FC, useEffect, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {CHAINS} from 'src/constants/chains';
import {useCurrentChainsList} from 'src/hooks/useCurrentChainsList';
import {ChainName} from 'src/types/MetadataApi';
import {IconsContainer, StyledIcon} from 'src/views/blockscout/styled';

export const BlockscoutNetworks: FC<
  {selectedId?: ChainName} & React.HTMLAttributes<HTMLDivElement>
> = ({selectedId, ...props}) => {
  const networksList = CHAINS.filter((chain) => chain.icon);
  const assets = useCurrentChainsList();

  const connectedNetworks = useMemo(
    () => (assets ? assets.map((asset) => asset.id) : []),
    [assets],
  );

  const postMessage = () => {
    window.parent.postMessage({networksHeight: `${window.document.body.scrollHeight}px`}, '*');
  };

  useEffect(() => {
    postMessage();
    window.addEventListener('resize', postMessage);

    return () => window.removeEventListener('resize', postMessage);
  }, []);

  return (
    <IconsContainer {...props}>
      {networksList.map((el) =>
        el.id === selectedId || !connectedNetworks.includes(el.id) ? (
          <StyledIcon
            key={el.id}
            selected={el.id === selectedId}
            disabled={!connectedNetworks.includes(el.id)}
          >
            {el.icon}
            {el.name !== 'Optimism' && <span>{el.name}</span>}
          </StyledIcon>
        ) : (
          <Link key={el.id} to={`/v2/${el.id}`} style={{textDecoration: 'none'}}>
            <StyledIcon>
              {el.icon}
              {el.name !== 'Optimism' && <span>{el.name}</span>}
            </StyledIcon>
          </Link>
        ),
      )}
    </IconsContainer>
  );
};
