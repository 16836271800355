import {t9nRenderJsx} from 'src/utils/dictionary';
import {knowledgeCenter} from '../knowledgeCenter';
import {StyledListItem, StyledSubtitle, StyledText} from '../styled';

export const Components = () => {
  const {
    contract,
    updaterLibrary,
    quotingLibrary,
    daemon,
    compatibilityContract,
    threatModel,
    protocols,
  } = knowledgeCenter.components;
  return (
    <>
      <StyledSubtitle>{contract.title}</StyledSubtitle>
      <StyledText>{contract.text}</StyledText>

      <StyledSubtitle>{updaterLibrary.title}</StyledSubtitle>
      <StyledText>{updaterLibrary.text}</StyledText>

      <StyledSubtitle>{quotingLibrary.title}</StyledSubtitle>
      {quotingLibrary.paragraphs.map((paragraph, index) => (
        <StyledText key={index}>{paragraph}</StyledText>
      ))}

      <StyledSubtitle>{daemon.title}</StyledSubtitle>
      <StyledText>{daemon.text}</StyledText>

      <StyledSubtitle>{compatibilityContract.title}</StyledSubtitle>
      <StyledText>{compatibilityContract.text}</StyledText>

      <StyledSubtitle>{threatModel.title}</StyledSubtitle>
      <ol>
        {threatModel.list.map((item, index) => (
          <StyledListItem key={index}>
            {t9nRenderJsx(item, {
              code: <code>{threatModel.code}</code>,
            })}
          </StyledListItem>
        ))}
      </ol>

      <StyledSubtitle>{protocols.title}</StyledSubtitle>
      <StyledText>{protocols.intro}</StyledText>
      <ol>
        {protocols.list.map((el, index) => (
          <StyledListItem key={index}>
            <b>{el.title}</b>
            <StyledText>{el.text}</StyledText>
            <b>cons:</b>
            <ul>
              {el.cons.map((item, i) => (
                <StyledListItem key={i}>
                  {t9nRenderJsx(item, {
                    code1: <code>{protocols.code1}</code>,
                    code2: <code>{protocols.code2}</code>,
                    code3: <code>{protocols.code3}</code>,
                  })}
                </StyledListItem>
              ))}
            </ul>
          </StyledListItem>
        ))}
      </ol>
      <StyledText>
        {t9nRenderJsx(protocols.conclusion, {
          code1: <code>{protocols.code1}</code>,
        })}
      </StyledText>
    </>
  );
};
