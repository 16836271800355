import {StyledListItem, StyledSmallSubtitle, StyledSubtitle, StyledText} from '../styled';

export const UpdatingOracle = () => (
  <>
    <StyledSubtitle>Epoch schedule</StyledSubtitle>
    <StyledText>
      Epoch number (== epoch start time) for unix time <code>t</code> is{' '}
      <code>t / EPOCH_DURATION * EPOCH_DURATION</code>. An epoch is divided into stages. Stage times
      below are unitless fractions and must be multiplied by EPOCH_DURATION to get the time in
      seconds since the start of the epoch. Participant must process an epoch only once.
    </StyledText>
    <StyledText>
      An epoch is divided into stages. Stage times below are unitless fractions and must be
      multiplied by <code>EPOCH_DURATION</code> to get the time in seconds since the start of the
      epoch. Participant must process an epoch only once.
    </StyledText>
    <StyledSubtitle>Commit stage</StyledSubtitle>
    <StyledText>The stage time range is [0, 0.15).</StyledText>

    <StyledSmallSubtitle>Participant</StyledSmallSubtitle>
    <StyledText>
      Detect prices for all known assets in any way feasible. If for an asset the price can’t be
      determined, use NO_PRICE (== max uint) as a special value. Create a uint prices array ordered
      the order of the assets. Compute the hash of the message Commit(address sender,uint32
      epochId,uint256[] prices) where sender is the participant’s address. Transact commit(epochId,
      hash) to the contract.
    </StyledText>
    <StyledSmallSubtitle>The contract</StyledSmallSubtitle>
    <StyledText>
      <code>commit(uint32 epochId, bytes32 hash)</code>
      {`. Check that epochId is current; the participant is valid; the participant has not yet transacted commit for the epochId. Record epochId => participant => hash & emit an event.`}
    </StyledText>

    <StyledSubtitle>Reveal stage</StyledSubtitle>
    <StyledText>
      The stage time range is [0.15, 0.20). If a quorum of the committed hashes is not present, the
      epoch is marked as failed (but no explicit storage write is needed), no further actions are
      needed.
    </StyledText>

    <StyledSmallSubtitle>Participant</StyledSmallSubtitle>
    <StyledText>
      Transact <code>reveal(epochId, prices, pricesSignature)</code> to the contract, where{' '}
      <code>prices</code> are the data created during the commit stage for the <code>epochId</code>.{' '}
      <code>pricesSignature</code> is a signature of the message
      <code>Reveal(uint32 epochId,uint256[] prices)</code>.
    </StyledText>
    <StyledText>
      <i>
        Comment: <code>pricesSignature</code> is needed not to trust the side network and not to
        assume correct functioning of the used side network node.
      </i>
    </StyledText>

    <StyledSmallSubtitle>The contract</StyledSmallSubtitle>
    <StyledText>
      <code>reveal(uint32 epochId, uint256[] prices, Signature pricesSignature)</code>. Check that{' '}
      <code>epochId</code> is current; the participant is valid; the participant has not yet
      transacted <code>reveal</code> for the <code>epochId</code>; the previously committed prices
      hash matches the hash of the <code>Commit</code> message constructed from{' '}
      <code>msg.sender, epochId, prices</code>.
    </StyledText>
    <StyledText>Emit epochId in an event.</StyledText>
    <StyledText>
      <i>
        Comment: no need to record all the prices to the state or emit them as they can be read by
        participants from the tx data.
      </i>
    </StyledText>

    <StyledSubtitle>Computation stage</StyledSubtitle>
    <StyledText>
      If a quorum of the revealed price arrays is not present, the epoch is marked as failed (but no
      explicit storage write is needed), no further actions are needed.
    </StyledText>
    <StyledSmallSubtitle>Participant</StyledSmallSubtitle>
    <StyledText>
      Any participant which successfully revealed during the current epoch does the following.
    </StyledText>
    <StyledText>
      Read successfully revealed prices from the corresponding transaction data. Take the
      participant set, quorum, current base prices, and epoch number from the mainnet contract. If
      the participant is not in the participant set, it stops the current epoch processing. Remove
      revealed prices reported by the participants not found on the mainnet or with incorrect
      <code>pricesSignature</code>. Remove duplicate submissions by the same participant. Compute
      the price for each asset: if no quorum is present (too many NO_PRICE or some were removed)
      then no price update (delta=0), the median of the prices otherwise (computed in Solidity uint
      terms).
    </StyledText>
    <StyledText>
      <i>
        Comment: filtering revealed prices is an extra safety check. Normally, nothing should be
        removed, see Participant set synchronization.
      </i>
    </StyledText>
    <StyledText>
      Translate resulting prices into full_update_assets, full_update_prices, new_delta_bytes arrays
      as in updater.py.
    </StyledText>
    <StyledText>
      Sign the message{' '}
      <code>
        Update(uint32 epochId,uint32 previousEpochId,address[] assets,uint256[] basePrices,bytes
        deltas)
      </code>{' '}
      (where <code>previousEpochId</code> is the current epoch number, <code>assets</code> are
      full_update_assets, <code>basePrices</code> are full_update_prices, <code>deltas</code> are
      new_delta_bytes).
    </StyledText>
    <StyledText>
      <i>
        Comment: <code>previousEpochId</code> is needed because deltas are relative, and an update
        is applicable only to the state for which it was created.
      </i>
    </StyledText>
    <ul>
      <StyledListItem>
        Multisignature case: transact <code>signed(epochId, signature)</code> to the contract.
      </StyledListItem>
      <StyledListItem>Threshold signature case: TBD</StyledListItem>

      <StyledSmallSubtitle>The contract</StyledSmallSubtitle>
      <ul>
        <StyledListItem>
          Multisignature case: <code>signed(uint32 epochId, signature)</code>: emit{' '}
          <code>epochId, signature</code> for a valid participant if not emitted yet and{' '}
          <code>epochId</code> is current.
        </StyledListItem>
        <StyledListItem>Threshold signature case: TBD</StyledListItem>
      </ul>
    </ul>

    <StyledSubtitle>Update stage</StyledSubtitle>
    <StyledSmallSubtitle>Participant</StyledSmallSubtitle>
    <StyledText>
      Gather a quorum of unique valid signatures or a threshold signature from the contract for the
      <code>Update</code> message produced at the previous stage. Use the participant set from the
      previous stage to check the validity of the signature participants. Transact to the mainnet
      contract along with the signature(s).
    </StyledText>
  </>
);
