import {Link} from 'react-router-dom';
import {breakpoint, color} from 'src/components/UI/theme';
import styled, {css} from 'styled-components';

export const FooterContent = styled.div`
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 5rem;
  padding-bottom: 5rem;
  svg {
    width: 4rem;
    height: auto;
  }
  @media (min-width: ${breakpoint.tablet}) {
    flex-direction: row;
    align-items: center;
  }
`;

const StyledLink = css`
  text-decoration: none;
  color: ${color.darkBlack};
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 2rem;
  :hover {
    text-decoration: underline;
  }

  @media (min-width: ${breakpoint.tablet}) {
    margin-bottom: 0;
    :not(last-of-type) {
      margin-right: 4rem;
    }
  }
`;

export const ExternalLink = styled.a`
  ${StyledLink}
`;

export const InternalLink = styled(Link)`
  ${StyledLink}
`;

export const FooterWrap = styled.footer`
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 3rem;
  min-width: 280px;
  width: 100%;
`;

export const SocialLinksWrap = styled.div`
  margin-top: 2rem;
  width: 100%;
  @media (min-width: ${breakpoint.tablet}) {
    margin-top: 0;
    margin-left: auto;
    width: auto;
  }
`;
