import {useState} from 'react';
import {CHAINS, SUPPORTED_NETWORKS} from 'src/constants/chains';
import {SectionWrap, StyledSubtitle} from 'src/views/home/styled';
import {IconsContainer, StyledIcon} from 'src/views/home/components/SupportedNetworks/styled';
import {ViewMoreButton} from 'src/views/home/components/ViewMoreButton';
import {LINKS} from 'src/constants/staticValues';

export const SupportedNetworks = () => {
  const [viewAll, setViewAll] = useState(false);
  const networksWithIcons = CHAINS.filter(
    (chain) => chain.icon && SUPPORTED_NETWORKS.includes(chain.id),
  );
  const networksList = viewAll ? networksWithIcons : networksWithIcons.slice(0, 8);

  return (
    <SectionWrap>
      <StyledSubtitle style={{maxWidth: '100%'}}>Supported Networks</StyledSubtitle>
      <IconsContainer>
        {networksList?.map((el) => (
          <a
            key={el.id}
            href={`${LINKS.explorer}/oracles/${el.id}`}
            target="_blank"
            rel="noreferrer"
            style={{color: 'currentcolor', textDecoration: 'none'}}
          >
            <StyledIcon isMultidata={el.name === 'Multidata'}>
              {el.icon}
              {el.name !== 'Optimism' && el.name !== 'Multidata' && (
                <span style={{fontSize: '2.5rem', fontWeight: 'bold'}}>{el.name}</span>
              )}
            </StyledIcon>
          </a>
        ))}
      </IconsContainer>
      <ViewMoreButton viewAll={viewAll} setViewAll={setViewAll} />
    </SectionWrap>
  );
};
