import {FC} from 'react';
import {Links} from './styled';
import {ReactComponent as GithubIcon} from 'src/assets/icons/ic-github.svg';
import {LINKS} from 'src/constants/staticValues';

export const SocialLinks: FC<React.HTMLProps<HTMLElement>> = (props) => (
  <Links onClick={props.onClick} style={props.style}>
    <a href={LINKS.github} target="_blank" rel="noopener noreferrer">
      <GithubIcon />
    </a>
  </Links>
);
