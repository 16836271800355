import {StyledText, StyledTitle} from '../styled';

export const ChainlinkUsers = () => (
  <>
    <StyledTitle>For Chainlink users</StyledTitle>
    <StyledText>
      We provide Chainlink-compatible aggregator (<code>IChainlinkAggregatorV2V3Interface</code>)
      for every quoted asset which allows to get the latest price of asset.
    </StyledText>
    <StyledText>
      See below example of getting prices for <code>ETH</code> and <code>UNI-V2 WETH-CRV</code> with
      chainlink compatible aggregators in Gnosis chain
    </StyledText>
    <pre>
      <code>
        {`// SPDX-License-Identifier: MIT
pragma solidity ^0.8.0;

import "./interfaces/IChainlinkAggregatorV2V3Interface.sol";

contract DataConsumerChainlinkCompatibility {
    
    IChainlinkAggregatorV2V3Interface internal aggregatorEth;
    IChainlinkAggregatorV2V3Interface internal aggregatorLpWethCrv;

    /**
     * network: Gnosis
     */
    constructor() {
        aggregatorEth = IChainlinkAggregatorV2V3Interface(0x05B10FAd302f202809BF2Fd1F1456e211C1A9B20);
        aggregatorLpWethCrv = IChainlinkAggregatorV2V3Interface(0xD3DCD5fEB3ffB266C6d9829607271dEa61eBa84C);
    }

    /**
     * Returns prices of 'ETH' and 'UNI-V2 WETH-CRV' in USD
     */
    function getLatestPrices() public view returns (uint[2] memory) {
        (,int priceEth,,,) = aggregatorEth.latestRoundData();
        (,int priceLP,,,) = aggregatorLpWethCrv.latestRoundData();

        return [
            uint(priceEth) / 10**8,
            uint(priceLP) / 10**8
        ];
    }
}`}
      </code>
    </pre>
  </>
);
