import {Link} from 'react-router-dom';
import {breakpoint, color} from 'src/components/UI/theme';
import styled from 'styled-components';

export const MenuWrap = styled.div`
  position: relative;
  @media (min-width: ${breakpoint.tablet}) {
    display: none;
  }
`;

export const HeaderMenuBtn = styled.button`
  border: none;
  background: ${color.gray};
  color: ${color.darkBlack};
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  cursor: pointer;
  outline: none;
  :hover {
    box-shadow: none;
    background-color: ${color.darkBlack};
    color: ${color.white};
  }
  @media (min-width: ${breakpoint.phone}) {
    transform: scale(0.8);
  }
`;

export const MenuContainer = styled.div`
  position: fixed;
  padding: 2rem;
  display: grid;
  grid-gap: 1rem;
  top: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.05);
  border-radius: 0 0 1.9rem 1.9rem;
  background: ${color.white};
  margin: 0;
  width: 100%;
  white-space: nowrap;

  @media (min-width: ${breakpoint.tablet}) {
    position: absolute;
    top: calc(100% + 0.5rem);
    width: auto;
    border-radius: 1.9rem;
  }
`;

export const StyledList = styled.ul`
  margin: 0;
  padding: 2rem 0;
  list-style: none;
  a {
    text-decoration: none;
    color: inherit;
    opacity: 0.8;
  }
  :not(:last-of-type) {
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
  }
  @media (min-width: ${breakpoint.tablet}) {
    #explorer-link {
      display: none;
    }
  }
`;

export const MenuItem = styled.li`
  padding: 1.25rem 3.5rem;
  font-size: 2.5rem;
  :hover {
    background: ${color.gray};
  }
  a {
    text-decoration: none;
    color: ${color.black};
  }
`;

export const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['disabled'].includes(prop),
})<{disabled?: boolean}>(
  ({disabled}) => `
  display: grid; 
  width: 100%;
  padding: 1.25rem 3.5rem;
  text-decoration: none;
  opacity: ${disabled ? 0.4 : 1};
  pointer-events: ${disabled ? 'none' : 'initial'};
`,
);

export const CloseButton = styled.div`
  display: block;
  color: ${color.black};
  justify-self: end;
  margin: 1.5rem 2.5rem 0;
  cursor: pointer;
  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
  @media (min-width: ${breakpoint.tablet}) {
    display: none;
  }
`;
