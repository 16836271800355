import {LINKS} from 'src/constants/staticValues';
import {StyledSubtitle, StyledText, StyledTitle} from 'src/views/knowledge-center/styled';

export const OracleApi = () => (
  <>
    <StyledTitle>Oracle Api reference</StyledTitle>
    <StyledSubtitle>ILegacyMetadataOracleV0_1</StyledSubtitle>
    <StyledText>
      Functions in <code>ILegacyMetadataOracleV0_1</code>
    </StyledText>
    <table style={{margin: '3.5rem 0'}}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>Returns</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <code>getStatus</code>
          </td>
          <td>Returns last update TS of prices</td>
          <td>
            <code>{`Status {uint32 updateTS;uint64 pricesHash;}`}</code>
          </td>
        </tr>
        <tr>
          <td>
            <code>getAssets</code>
          </td>
          <td>Gets a list of assets quoted by this oracle.</td>
          <td>
            <code>address[]</code>
          </td>
        </tr>
        <tr>
          <td>
            <code>hasAsset(address asset)</code>
          </td>
          <td>Checks if an asset is quoted by this oracle.</td>
          <td>
            <code>bool</code>
          </td>
        </tr>
        <tr>
          <td>
            <code>quoteAssets(address[] calldata assets)</code>
          </td>
          <td>Gets last known quotes for the assets</td>
          <td>
            <code>{`Quote[] {uint256 price;uint32 updateTS;}`}</code>
          </td>
        </tr>
      </tbody>
    </table>
    <StyledTitle style={{marginBottom: '2rem'}}>SDK</StyledTitle>
    <a href={LINKS.sdk} target="_blank" rel="noopener noreferrer">
      chunk-oracle-sdk
    </a>
  </>
);
