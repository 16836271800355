import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as CloseIcon} from 'src/assets/icons/ic-close-modal.svg';
import {LINKS} from 'src/constants/staticValues';
import {SocialLinks} from 'src/layout/SocialLinks';
import {ReactComponent as DotsIcon} from 'src/assets/icons/ic-header-info.svg';
import {
  CloseButton,
  HeaderMenuBtn,
  MenuContainer,
  MenuItem,
  MenuWrap,
  StyledList,
} from 'src/layout/Header/Menu/styled';

export const Menu = () => {
  const [showMenu, setShowMenu] = useState(false);

  function onCloseMenu() {
    setShowMenu(false);
  }

  function onMenuBtnHandler(e: React.MouseEvent) {
    e.stopPropagation();
    setShowMenu(!showMenu);
  }

  useEffect(() => {
    if (!showMenu) return;

    document.addEventListener('click', onCloseMenu);

    return () => document.removeEventListener('click', onCloseMenu);
  }, [showMenu]);

  return (
    <MenuWrap>
      <HeaderMenuBtn onClick={onMenuBtnHandler}>
        <DotsIcon />
      </HeaderMenuBtn>
      {showMenu && (
        <MenuContainer>
          <CloseButton onClick={onCloseMenu}>
            <CloseIcon />
          </CloseButton>

          <StyledList>
            <MenuItem>
              <a href={LINKS.sdk} target="_blank" rel="noopener noreferrer">
                SDK
              </a>
            </MenuItem>
            <MenuItem>
              <a href={LINKS.docs} target="_blank" rel="noopener noreferrer">
                Docs
              </a>
            </MenuItem>
            <MenuItem>
              <a href={LINKS.explorer} target="_blank" rel="noopener noreferrer">
                Explorer
              </a>
            </MenuItem>
          </StyledList>
          <SocialLinks onClick={onCloseMenu} style={{padding: '1.25rem 3.5rem'}} />
        </MenuContainer>
      )}
    </MenuWrap>
  );
};
