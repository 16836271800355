import React from 'react';
import {knowledgeCenter} from '../knowledgeCenter';
import {StyledText, StyledTitle} from '../styled';

export const Flow = () => {
  const {sections} = knowledgeCenter.flow;
  return (
    <>
      {sections.map((section, index) => (
        <React.Fragment key={index}>
          <StyledTitle>{section.title}</StyledTitle>
          {section.paragraphs.map((paragraph, i) => (
            <StyledText key={i}>{paragraph}</StyledText>
          ))}
        </React.Fragment>
      ))}
    </>
  );
};
