import {StyledText, StyledTitle} from '../styled';

export const DefiExamples = () => (
  <>
    <StyledTitle>Examples of usage by DEFI projects</StyledTitle>
    <StyledText>Let’s describe line-by-line how it works:</StyledText>
    <pre>
      <code>
        {`...
interface IOracleUsd {
    function assetToUsd(address asset, uint256 amount) external view returns (uint256);
}

/// @title MetadataOracle wrapper for Unit protocol
contract UnitMetadataOracle is IOracleUsd {
    // Multidata oracle's address is stored in immutable. It allows to save gas on read of this variable
    ICoreMetadataOracleReader public immutable metadataOracle;
    // Also immutable variable, stores max allowed price age
    uint256 public immutable maxPriceAgeSeconds;

    constructor(address metadataOracle_, uint256 maxPriceAgeSeconds_) {
        metadataOracle = ICoreMetadataOracleReader(metadataOracle_);
        maxPriceAgeSeconds = maxPriceAgeSeconds_;
    }

    /**
     * @notice Evaluates the cost of amount of asset in USD.
     * @dev reverts on non-supported asset or stale price.
     * @param asset evaluated asset
     * @param amount amount of asset in the smallest units
     * @return result USD value, scaled by 10**18 * 2**112
     */
    function assetToUsd(address asset, uint256 amount) external view override returns (uint256 result) {
        // Prepare arguments for calling oracle. Despite the dact that we need to quote only one price
        // we still need to make array
        address[] memory input = new address[](1);
        input[0] = asset;
        // quoteAssets returns array. Since we pass only one asset in argument only one element is returned.
        ICoreMetadataOracleReader.Quote memory quote = metadataOracle.quoteAssets(input)[0];
        // Here we see very important concept of using oracles: we need to check that price returned by oracle
        // is up to date. Usage of outdated price may lead to losses
        require(block.timestamp - quote.updateTS <= maxPriceAgeSeconds, 'STALE_PRICE');

        // Multidata oracle store price for whole unit of currency (taking in account 2**112 base)
        // For example price for 1 eth is stored as X * 2**112 USD where X is the price in USD
        // Unit protocol wants to get price for amount of asset passed in min units of asset in USD with decimals 18
        // For example to get price for 1 WETH unit protocol passes 10**18 as amount 
        // and wants to get X * 10**18 * 2**112 in response where X is the price in USD
        // If asset has decimal <> 18 price must be scaled accordingly
        uint256 decimals = uint256(IERC20Like(asset).decimals());
        require(decimals < 256);
        // Let's assume that we need to get price for 1 USDT which has decimals = 6.
        // 10**6 is passed as amount
        // scaleDecimals = 18-6=12
        int256 scaleDecimals = 18 - int256(decimals);

        // result = 1 * 2**112 * 10**6
        result = quote.price * amount;
        if (scaleDecimals > 0)
        // result = 1 * 2**112 * 10**6 * 10**12 = 1 * 2**112 * 10**18. So price 1 with base 2**112 and decimals 18 is returned
            result *= uint256(10) ** uint256(scaleDecimals);
        else if (scaleDecimals < 0)
            result /= uint256(10) ** uint256(-scaleDecimals);
    }
}`}
      </code>
    </pre>
  </>
);
