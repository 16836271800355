// import moment from 'moment';
import {BehaviorSubject} from 'rxjs';
import {ChainMetadata, ChainName} from 'src/types/MetadataApi';
import {api} from '../api';
import {SYS_BTC, SYS_TOKEN} from '../constants/collaterals';
import {AssetsStoreMap, AssetType, Version} from '../types/Asset';
import {getAssetTypeByName} from '../utils/getAssetType';

export const assetsStore = {
  assets: new BehaviorSubject<AssetsStoreMap>({} as AssetsStoreMap),
  initialized: new BehaviorSubject(false),

  async init(): Promise<void> {
    if (this.initialized.getValue()) return;
    assetsStore.initialized.next(false);
    await assetsStore.fetchDataAndFillStore();
    assetsStore.initialized.next(true);
  },

  async fetchDataAndFillStore(): Promise<void> {
    const resp = await api.metadata.getMetadata();
    const assets = {} as AssetsStoreMap;
    resp.data.forEach((el) => {
      const multiparty = el?.multiparty;
      const version = (multiparty ? 'v2' : 'v1') as Version;

      // const cur = moment().utc();
      // const last = moment(el.updated * 1000);

      // if (cur.diff(last, 'hours') > 24) {
      //   return;
      // }

      if (el.updated)
        if (!assets[version]) {
          assets[version] = {} as {
            [chainName in ChainName]: {
              [key in AssetType]?: ChainMetadata;
            };
          };
        }

      if (!assets[version][el.provider]) {
        assets[version][el.provider] = {};
      }

      el.assets.forEach((elem) => {
        if (!elem.metric) {
          let token;
          if (elem.address === SYS_TOKEN) token = {...elem, address: '', symbol: 'SYS'};
          else if (elem.address === SYS_BTC) token = {...elem, address: '', symbol: 'BTC'};
          else token = {...elem, address: elem.address.toLowerCase()};
          if (multiparty) {
            const {participants, quorum, signers} = multiparty;
            const multipartyPrices = {} as Record<string, string>;
            Object.entries(multiparty.prices).forEach(([participant, prices]) => {
              multipartyPrices[participant] = prices[elem.address];
            });
            token.multiparty = {
              prices: multipartyPrices,
              participants,
              quorum,
              signers,
            };
          }
          const currentTokens = assets[version][el.provider].tokens;
          if (currentTokens) currentTokens.assets = [...currentTokens.assets, token];
          else {
            assets[version][el.provider].tokens = {
              ...el,
              assets: [token],
            };
          }
        } else {
          const type = getAssetTypeByName(elem.metric.name);
          const symbol =
            type === 'tokens'
              ? elem.symbol
              : `${elem.metric.description} (${elem.symbol.split('::').pop()})`;
          const currentType = assets[version][el.provider][type];
          elem.symbol = symbol;
          if (type === 'tokens' && elem.address.length !== 42) elem.address = '';
          if (type !== 'tokens') elem.address = '';
          if (multiparty && elem.address) {
            const {participants, quorum, signers} = multiparty;
            const multipartyPrices = {} as Record<string, string>;
            Object.entries(multiparty.prices).forEach(([participant, prices]) => {
              multipartyPrices[participant] = prices[elem.address];
            });
            elem.multiparty = {
              prices: multipartyPrices,
              participants,
              quorum,
              signers,
            };
          }
          if (currentType) currentType.assets = [...currentType.assets, elem];
          else {
            assets[version][el.provider][type] = {
              ...el,
              assets: [elem],
            };
          }
        }
      });
    });
    assetsStore.assets.next(assets);
  },

  async refresh(): Promise<void> {
    await assetsStore.init();
  },
};
