import React from 'react';
import {Toaster} from 'react-hot-toast';
import {color} from '../theme';

export const Notification: React.FC = () => (
  <Toaster
    position={'bottom-center'}
    toastOptions={{
      duration: 2000,
      style: {maxWidth: 'unset', fontSize: '2rem', color: color.white, background: color.black},
    }}
  />
);
