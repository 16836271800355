import {StyledListItem, StyledText, StyledTitle} from '../styled';
import {knowledgeCenter} from '../knowledgeCenter';

export const Specification = () => {
  const {title, text, list} = knowledgeCenter.specification;
  return (
    <>
      <StyledTitle>{title}</StyledTitle>
      <StyledText>{text}</StyledText>
      <ul>
        {list.map((item, index) => (
          <StyledListItem key={index}>{item}</StyledListItem>
        ))}
      </ul>
    </>
  );
};
