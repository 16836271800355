export const Q112 = '0x10000000000000000000000000000';

export const CONTRACT_ADDRESS = '0xf315a5cc91338a3886d3c3a11E7b494f3302B3fA';

export const LINKS = {
  explorer: 'https://explorer.chunk.limo',
  sdk: 'https://github.com/chunklimo/sdk',
  github: 'https://github.com/chunklimo',
  booster: 'https://booster.foundation/',
  docs: 'https://docs.chunk.limo/',
};
