import React from 'react';
import {ChainName} from '../types/MetadataApi';
import {ReactComponent as PolygonIcon} from '../assets/chains/polygon.svg';
import {ReactComponent as ArbitrumIcon} from '../assets/chains/arbitrum.svg';
import {ReactComponent as AstarLogo} from '../assets/chains/astar.svg';
import {ReactComponent as AuroraIcon} from '../assets/chains/aurora.svg';
import {ReactComponent as AvalancheIcon} from '../assets/chains/avalanche.svg';
import {ReactComponent as BittorrentIcon} from '../assets/chains/bittorrent.svg';
import {ReactComponent as BnbIcon} from '../assets/chains/bnb.svg';
import {ReactComponent as BobaIcon} from '../assets/chains/boba.svg';
import {ReactComponent as CantoIcon} from '../assets/chains/canto.svg';
import {ReactComponent as CeloIcon} from '../assets/chains/celo.svg';
import {ReactComponent as CLVIcon} from '../assets/chains/clv.svg';
import {ReactComponent as CronosIcon} from '../assets/chains/cronos.svg';
import {ReactComponent as DogechainIcon} from '../assets/chains/dogechain.svg';
import {ReactComponent as EOSIcon} from '../assets/chains/eos.svg';
import {ReactComponent as EtcIcon} from '../assets/chains/etc.svg';
import {ReactComponent as FantomOperaIcon} from '../assets/chains/fantom-opera.svg';
import {ReactComponent as FusionLogo} from '../assets/chains/fusion.svg';
import {ReactComponent as GnosisIcon} from '../assets/chains/gnosis.svg';
import {ReactComponent as HarmonyIcon} from '../assets/chains/harmony.svg';
import {ReactComponent as HecoIcon} from '../assets/chains/heco-mainnet.svg';
import {ReactComponent as KlaytnIcon} from '../assets/chains/klaytn.svg';
import {ReactComponent as LineaIcon} from '../assets/chains/linea.svg';
import {ReactComponent as MantleLogo} from '../assets/chains/mantle.svg';
import {ReactComponent as MetisIcon} from '../assets/chains/metis.svg';
import {ReactComponent as MoonbeamIcon} from '../assets/chains/moonbeam.svg';
import {ReactComponent as MoonriverIcon} from '../assets/chains/moonriver.svg';
import {ReactComponent as OasisNetworkIcon} from '../assets/chains/oasis.svg';
import {ReactComponent as OkcIcon} from '../assets/chains/okc.svg';
import {ReactComponent as OptimismIcon} from '../assets/chains/optimism.svg';
import {ReactComponent as SyscoinIcon} from '../assets/chains/syscoin.svg';
import {ReactComponent as WemixLogo} from '../assets/chains/wemix.svg';
import {ReactComponent as MultidataLogo} from '../assets/logo.svg';
import {LINKS} from './staticValues';

export const CHAINS = [
  {
    name: 'EOS',
    id: 'eos',
    icon: <EOSIcon />,
    explorer: 'https://explorer.evm.eosnetwork.com',
  },
  {
    name: 'Bsc',
    id: 'bsc',
    icon: <BnbIcon />,
    explorer: 'https://bscscan.com',
    contracts: {
      metadataOracle: '0x73F23073b4778f8Cc13a4AbD4C98269C0D47AE3C',
      metadataOracleProxy: '0x7698e538Eb7273CBbF31CDe646032fbC74C44481',
      unitMetadataOracle: '0x5468DD9B12876E8AaeDEC47b39cD42BfA2aAb1f7',
      chainlinkCompatibility: '0x6d230D2b066977b7Ae5357d4A9738a1105E2e539',
      helper: '0x77Dd8AE2dCdF3dCF499C843cf36686819F46E7e0',
      newParticipantsMutationsStorage: '0x27269534374991C1724703c71B1B6860A1bBfC5A',
    },
    validators: [
      '0x19572fdB47243B9Ac72632817e96c7F3e796add1',
      '0x84F348933D497033e854EE4b8d3B4d2dAEd03d34',
      '0xC89DE497946820014cf0A2f816B26399a02C0348',
      '0x2E70c22722512f4AcC6344F258c04669828ea865',
      '0xa596cf83e904001f19235C235971fbe59e2302e8',
      '0x64BDC0B0Df2eDF9fEB7D6569B18365deAAC8f315',
      '0xe4B8181B5CFa1e7A61742a1DC87E2daCa75c62B6',
      '0x4A05DbAbC3f6CEa9F5A66eE03C4121864bc9ae20',
      '0xa1E0a010Add705C20A2EAfAb900F75aDF13E2955',
      '0x7c76dEA2Bf62Ac269DAFc0F4ae37d90Fc8986C65',
    ],
  },
  {
    name: 'Gnosis',
    id: 'xdai',
    icon: <GnosisIcon />,
    explorer: 'https://blockscout.com/xdai/mainnet',
    contracts: {
      metadataOracle: '0x73F23073b4778f8Cc13a4AbD4C98269C0D47AE3C',
      metadataOracleProxy: '0x7698e538Eb7273CBbF31CDe646032fbC74C44481',
      unitMetadataOracle: '0x5468DD9B12876E8AaeDEC47b39cD42BfA2aAb1f7',
      chainlinkCompatibility: '0x6d230D2b066977b7Ae5357d4A9738a1105E2e539',
      helper: '0xca66545cf5A84B6C01a7574ABa09dDca3fbACcd6',
      newParticipantsMutationsStorage: '0x0c880556Ba5C46c8cA783A25Ae859CF4d434b030',
    },
    validators: [
      '0x5234aBD02fA5A2293aEc79d1b7378f83D6dB3B68',
      '0xa6b11b39C2C416e4ccc5157931FE031A395caEAd',
      '0xF371338A626D58617581C0225F4b4C2AF16917b5',
      '0x1C585f0Bd0EFa91d21c284719d3f5b6a48cCf21f',
      '0xb45Fb25F1daAc97d842312FF421E502e238c5160',
      '0x31F0E0eBFa40B66B3E2775AFcEC92ebDBe2CD599',
      '0x73b6e3AF41Fa52ec6349A5a47f9cf40A6D862Ca3',
      '0x828a70Bae97f64C34EF5167EceB51c2fF82c70b9',
      '0x6c35268FB33C557D4bcAD80dBe8772e8012a9cF8',
      '0x853385186841Ecdfe63DBd17d857853ce4Dc867e',
    ],
  },
  {
    name: 'Polygon',
    id: 'polygon',
    icon: <PolygonIcon />,
    explorer: 'https://polygonscan.com',
    contracts: {
      metadataOracle: '0x73F23073b4778f8Cc13a4AbD4C98269C0D47AE3C',
      metadataOracleProxy: '0x7698e538Eb7273CBbF31CDe646032fbC74C44481',
      unitMetadataOracle: '0x5468DD9B12876E8AaeDEC47b39cD42BfA2aAb1f7',
      chainlinkCompatibility: '0x6d230D2b066977b7Ae5357d4A9738a1105E2e539',
      helper: '0xBcF0607C470C78e80f7C77bcb082cc5ac4c3B55e',
      newParticipantsMutationsStorage: '0xd214284B05C99B9BcE0295A9D9D6EFB15be8582A',
    },
    validators: [
      '0xC2638Ce07838805A0791f7368881Fa8d2D0784eA',
      '0xfe8a6EaCa97bfD09F903bA9049f4B5DAfD1b9E00',
      '0xaB6BEd0b933Fd52EbcB17Ff72EC07F725db02BDA',
      '0x488D131D311b88E1e08262C3CE80aDc058c1E6Ef',
      '0x0358106c6596e4e975373a59F02BE1753041393d',
      '0xc27587C8adcF80A8DE64cf0EB4BfBf5fE416B37e',
      '0xA45298932C9191A31dda2498A28fc51bE3870711',
      '0x7038D4f69c4E63F944e865De4a48f93835e417B2',
      '0x007ceb853b9cb66b87E50Fa3F2488711372BF115',
      '0x564812b5D759bB91366dBc96388bdb9E0E4F92d5',
    ],
  },
  {
    name: 'Optimism',
    id: 'optimism',
    icon: <OptimismIcon />,
    explorer: 'https://optimistic.etherscan.io',
  },
  {
    name: 'Fantom',
    id: 'fantom',
    icon: <FantomOperaIcon />,
    explorer: 'https://ftmscan.com',
    contracts: {
      metadataOracle: '0x73F23073b4778f8Cc13a4AbD4C98269C0D47AE3C',
      metadataOracleProxy: '0x7698e538Eb7273CBbF31CDe646032fbC74C44481',
      unitMetadataOracle: '0x5468DD9B12876E8AaeDEC47b39cD42BfA2aAb1f7',
      chainlinkCompatibility: '0x6d230D2b066977b7Ae5357d4A9738a1105E2e539',
      helper: '0x5619404264d363d8CD1051bEFAD91021c51f3582',
      newParticipantsMutationsStorage: '0xEC3f24Ff910A02E899B439b243a9bffCc781D34e',
    },
    validators: [
      '0x624cc1a9082c271aC5E8e95Fe7165564B508BE65',
      '0x9126CF2Df04B7a9a82539E2F7a4E810A7249f6B4',
      '0xCB52460B4048d3e5ce54eF6669920568c6e34571',
      '0xf1F97ADb5f6c87A0edEdC0A59a4AEB796FA04175',
      '0xC803e8CAeAA08C87Ea3F42AE1fBa19E8d018A611',
      '0xb99F76c4c7147a15380109C5B085B1ec1F858a0F',
      '0xe65b8D7E806aff4A5960f04117Fb6F29c2Aa376D',
      '0xE2593b55A7253c20e4657E3b8B49f6580e2660C7',
      '0x9E99089663badc18a18F9ec33640980F3972387f',
      '0xb9Dc410fDDCD6148a51724aA1bd1A1c7E4905a2C',
    ],
  },
  {
    name: 'Avalanche',
    id: 'avalanche',
    icon: <AvalancheIcon />,
    explorer: 'https://avascan.info/blockchain/stepnetwork',
    contracts: {
      metadataOracle: '0x73F23073b4778f8Cc13a4AbD4C98269C0D47AE3C',
      metadataOracleProxy: '0x7698e538Eb7273CBbF31CDe646032fbC74C44481',
      unitMetadataOracle: '0x5468DD9B12876E8AaeDEC47b39cD42BfA2aAb1f7',
      chainlinkCompatibility: '0x6d230D2b066977b7Ae5357d4A9738a1105E2e539',
      helper: '0xA09291a2f5c0e9EcBb1f5c5476000171461eB5BE',
      newParticipantsMutationsStorage: '0xA1FC1d40e97157AD5D2F4E62F64B9425df91cF0E',
    },
    validators: [
      '0x9B222FE278624dD735D622CfbB3526B42C92D2b5',
      '0x73de4417FbBa5F6023A40f9C8A226C841f71D5b8',
      '0xE8F4DF8843d7E6904F805fCE45F9C99d8c97ead7',
      '0x303d840cA0ccFDE2519CD736854748dE168c230c',
      '0x3C6ADb3e414b39D41FdCd7fCaA94bcD9e98B11C7',
      '0xA4C4ba5EB477c0d5e62b679060708e91D232f8d4',
      '0x2E4Ec24Cd6b1A62828D9c58620dAd44732644C2E',
      '0x2e223dd497a440F76269D7eb502C4F0E055B91A9',
      '0xc480e3E58E52557496CEeDF46021dF21dEe368E1',
      '0x755059406ba247841A856459dc15Daaa727F376f',
    ],
  },
  {
    name: 'Harmony',
    id: 'harmony',
    icon: <HarmonyIcon />,
    explorer: 'https://explorer.harmony.one',
    contracts: {
      metadataOracle: '0x73F23073b4778f8Cc13a4AbD4C98269C0D47AE3C',
      metadataOracleProxy: '0x7698e538Eb7273CBbF31CDe646032fbC74C44481',
      unitMetadataOracle: '0x5468DD9B12876E8AaeDEC47b39cD42BfA2aAb1f7',
      chainlinkCompatibility: '0x6d230D2b066977b7Ae5357d4A9738a1105E2e539',
      helper: '0xB57b3F624C3329E4411C466442f656beD16ca86b',
      newParticipantsMutationsStorage: '0xeD3E0F1a7bbFA186f2d7827D6e2b912374129119',
    },
    validators: [
      '0x3ee54d016ea02a9D14Fa8Ea3b4E7dEe02012BD4e',
      '0x7eAF5345C01f7d880c97d637fFc5A28df054E122',
      '0x67A39105934b53F67ae873E825D7f4c64ffFa0C2',
      '0xe31732680F559864d869b1E4a500a0b019888313',
      '0xd332bEcF0C403B5535C0b5757FAbC622C2345190',
      '0x90881272fC26a4D0f7739a2C96a3d54E67a88Df2',
      '0xE66642d6B80590283c982a878681306671B63d26',
      '0xe59301C2DCC1995e1a24c003b6B51e94ad308F92',
      '0x326383E127eF68c2b65dEC95CAfB5365950AcAb5',
      '0x45D38bA65acaC9470F285A3f13DC9C64BC4387b6',
    ],
  },
  {
    name: 'Heco',
    id: 'heco',
    icon: <HecoIcon />,
    explorer: 'https://hecoinfo.com',
  },
  {
    name: 'Oasis',
    id: 'oasis',
    icon: <OasisNetworkIcon />,
    explorer: 'https://explorer.emerald.oasis.dev',
  },
  {
    name: 'Arbitrum',
    id: 'arbitrum',
    icon: <ArbitrumIcon />,
    explorer: 'https://arbiscan.io',
  },
  {
    name: 'Syscoin',
    id: 'syscoin',
    icon: <SyscoinIcon />,
    explorer: 'https://explorer.syscoin.org',
  },
  {
    name: 'Boba',
    id: 'boba',
    icon: <BobaIcon />,
    explorer: 'https://blockexplorer.boba.network',
  },
  {
    name: 'Okex',
    id: 'okex',
    icon: <OkcIcon />,
    explorer: 'https://www.oklink.com/okc',
    contracts: {
      metadataOracle: '0x73F23073b4778f8Cc13a4AbD4C98269C0D47AE3C',
      metadataOracleProxy: '0x7698e538Eb7273CBbF31CDe646032fbC74C44481',
      unitMetadataOracle: '0x5468DD9B12876E8AaeDEC47b39cD42BfA2aAb1f7',
      chainlinkCompatibility: '0x6d230D2b066977b7Ae5357d4A9738a1105E2e539',
      helper: '0x46273964C07f0302a5647e8803b3Dc1bC222D322',
      newParticipantsMutationsStorage: '0x4B86048e8285ab50f8B48A8A05d29Fbef603b39b',
    },
    validators: [
      '0x68CE943F43e5602CbF7Cd2155e5d390682b58C59',
      '0x4bCE970B9D53F9c12A1127C19a611a80220e7caE',
      '0x968Be2dC98aE1FB09cf1eC35a30900f400CE6f21',
      '0x2aD2D490ffd40b50D9bfBB6e8BF9F2516ae4Bdb7',
      '0x8fe800e31029d8F041A08a817AC936A3031CD06e',
      '0x8Dd225A339171b0f619aD32B51b65c3BE0fe51bC',
      '0xdeEb2Dd1d55f8A15545c6E0B26930af5EfABBDf3',
      '0x3957C02Fa40Ba66D05F677239fc6A8CdF84980A6',
      '0xdcb1c6265768C3BA27ebAe5FC80C26692a39758D',
      '0xC442078107d883dA0634c6D570Adba152634580A',
    ],
  },
  {
    name: 'Moonriver',
    id: 'moonriver',
    icon: <MoonriverIcon />,
    explorer: 'https://moonriver.moonscan.io',
    contracts: {
      metadataOracle: '0x73F23073b4778f8Cc13a4AbD4C98269C0D47AE3C',
      metadataOracleProxy: '0x7698e538Eb7273CBbF31CDe646032fbC74C44481',
      unitMetadataOracle: '0x5468DD9B12876E8AaeDEC47b39cD42BfA2aAb1f7',
      chainlinkCompatibility: '0x6d230D2b066977b7Ae5357d4A9738a1105E2e539',
      helper: '0xb1608716497060ca3615285e4ED8C19572049169',
      newParticipantsMutationsStorage: '0xbb0E3fDC71e810159DCBF552466b4D1bf326e337',
    },
    validators: [
      '0x67F5a7587863B3f50ECcB4b4DF5dc19452D92bb2',
      '0xEEBe2fc2b78A72D237c901adD929FD03D88E8129',
      '0x41365Fe95e0D491b1a8e3877bA1C8419dd23E11D',
      '0xF9fa736557D7908547C07c7AbD6676962c26FC73',
      '0xD1627814719991cD2f739894767608724811Da6F',
      '0x85aC27bD3Bbf37F3457589D141ECD7E6d1D7a4F5',
      '0xeBe0CCcF3eb3c06f3398Cc6847F1c5BA8c07F7aD',
      '0x8cc28C61A5fD98751e4207e5c78Fdb1f7C617d33',
      '0x2B62c85a5AF12eBdFa83Ed7338906705Ee84f50c',
      '0x2f413DD8B931EB8076F8109F853B5bf66E444F8F',
    ],
  },
  {
    name: 'Celo',
    id: 'celo',
    icon: <CeloIcon />,
    explorer: 'https://celoscan.io',
    contracts: {
      metadataOracle: '0x73F23073b4778f8Cc13a4AbD4C98269C0D47AE3C',
      metadataOracleProxy: '0x7698e538Eb7273CBbF31CDe646032fbC74C44481',
      unitMetadataOracle: '0x5468DD9B12876E8AaeDEC47b39cD42BfA2aAb1f7',
      chainlinkCompatibility: '0x6d230D2b066977b7Ae5357d4A9738a1105E2e539',
      helper: '0x39A8c8Fc923B6f7EDfd5b3c6A5Af0f04e2ff148B',
      newParticipantsMutationsStorage: '0x2C1C3D799b61Ad42ca698380caC5C31F676a509a',
    },
    validators: [
      '0x0F049E55451096f4b92F5F537389D479360f56D3',
      '0x80D7841829D9793BdC1bF6e28f6fA3Eb32Aa6133',
      '0x8B52A4689Ba32Ab82B0895dBC8d8D080F2A26aE5',
      '0xf67E210e2345C85D2Cd10A2dB8F8F4c9D16E7aCC',
      '0xE3659d78228e7b3915a6f7228a6Aa1a022d66FA4',
      '0xfD8505E82d1218B64dC5C476E5D4EcD2d1411cf0',
      '0x0db90875472120F3fA3698b3c3DD1F5DAccf5617',
      '0xB8ae9fc3f60A61EB7F1D62718a68c961AcA2A894',
      '0xED1D21619f3E5C780e99414c98713C42368d503B',
      '0x2F0FCd70d81786fA7654028a0F8987fc8277F849',
    ],
  },
  {
    name: 'Fusion',
    id: 'fusion',
    icon: <FusionLogo />,
    explorer: 'https://fsnscan.com/',
  },
  {
    name: 'Mantle',
    id: 'mantle',
    icon: <MantleLogo />,
    explorer: 'https://explorer.mantle.xyz/',
  },
  {
    name: 'Metis',
    id: 'metis',
    icon: <MetisIcon />,
    explorer: 'https://explorer.metis.io/',
  },
  {
    name: 'Wemix',
    id: 'wemix',
    icon: <WemixLogo />,
    explorer: 'https://explorer.wemix.com/',
  },
  {
    name: 'Astar',
    id: 'astar',
    icon: <AstarLogo />,
    explorer: 'https://astar.subscan.io/',
  },
  {
    name: 'X1',
    id: 'x1',
    icon: <OkcIcon />,
    explorer: 'https://www.oklink.com/x1-test',
  },
  {
    name: 'Canto',
    id: 'canto',
    icon: <CantoIcon />,
    explorer: 'https://cantoscan.com/',
  },
  {
    name: 'Moonbeam',
    id: 'moonbeam',
    icon: <MoonbeamIcon />,
    explorer: 'https://moonscan.io',
    contracts: {
      metadataOracle: '0x73F23073b4778f8Cc13a4AbD4C98269C0D47AE3C',
      metadataOracleProxy: '0x7698e538Eb7273CBbF31CDe646032fbC74C44481',
      unitMetadataOracle: '0x5468DD9B12876E8AaeDEC47b39cD42BfA2aAb1f7',
      chainlinkCompatibility: '0x6d230D2b066977b7Ae5357d4A9738a1105E2e539',
      helper: '0x44eb4bcD38B60aCB20c66b8ab74d7efFA7443c54',
      newParticipantsMutationsStorage: '0xfE2ce5Bd5e0b8D96234CF03738767667838c75d0',
    },
    validators: [
      '0xfaC5AdFA4a40f794c2f12363053F3b548E2cd9D4',
      '0x4Af0315f218B029a63941e50Af25DFC43D38eCbD',
      '0xE6Ef2183b6D77cb56Ec2dBA75642a55AB4D3162D',
      '0xa8e1405b3470fc10E4C303f2De47fBB9Dc05811E',
      '0xE162F04c4fec28fe1B0fdba7778F9E83a7994727',
      '0x917F063A86c6Ddc794287175DA496298DC61f268',
      '0x0f723870dd4331B03884D2bfd75cCd56f9B7C9f0',
      '0x2411e5808DD43721541b9E200B4DB3c27aA9037D',
      '0xd73C3c7A33E88d84Bd22D5D1cC48fE9C87eF8f9a',
      '0x5528Ed83F1a1024690F980578FF1D17cBd439806',
    ],
  },
  {
    name: 'Ethereum',
    id: 'etc',
    icon: <EtcIcon />,
    explorer: 'https://etherscan.io/',
    contracts: {
      metadataOracle: '0x73F23073b4778f8Cc13a4AbD4C98269C0D47AE3C',
      metadataOracleProxy: '0x7698e538Eb7273CBbF31CDe646032fbC74C44481',
      unitMetadataOracle: '0x5468DD9B12876E8AaeDEC47b39cD42BfA2aAb1f7',
      chainlinkCompatibility: '0x6d230D2b066977b7Ae5357d4A9738a1105E2e539',
      helper: '0x39CC06B564C828adc0FeDA633719BDa13CD42db5',
      newParticipantsMutationsStorage: '0x955779287Cd3286377b03aC033e31D3Acb024589',
    },
    validators: [
      '0x36c7C936CF6fd6adEBAb9FCcDC21269a829B96D0',
      '0x7E130bE6f820611d1Eabd01805a9261a063BBC36',
      '0x16faDd32329301887a74DFF93D0220b103A18403',
      '0x1e1Ef728Db3A4aAdc23B343F29c1B3551b95a4CE',
      '0x226C78f206064b91b6634da5Ae06Ef848fe3FAe8',
      '0xD5D9756c77ACeB093C8513DE84382a88dd43FE53',
      '0x1B4F843a2041021F248d461B11FaB7B17127761e',
      '0x8E2C84E8629a0338f1719717a2d79202c84FeC1e',
      '0x7C54Be23519c70aeAc6Dc943131aa2e9FF59691f',
      '0xbe96e813d27f300c202c710CdC754A09Ae5770F5',
    ],
  },
  {
    name: 'CLV Chain',
    id: 'clover',
    icon: <CLVIcon />,
    explorer: 'https://clvscan.com',
    contracts: {
      metadataOracle: '0x73F23073b4778f8Cc13a4AbD4C98269C0D47AE3C',
      metadataOracleProxy: '0x7698e538Eb7273CBbF31CDe646032fbC74C44481',
      unitMetadataOracle: '0x5468DD9B12876E8AaeDEC47b39cD42BfA2aAb1f7',
      chainlinkCompatibility: '0x6d230D2b066977b7Ae5357d4A9738a1105E2e539',
      helper: '0x09f235F31C205c774bf1CFBF3932CAaf683f06D7',
      newParticipantsMutationsStorage: '0xDd9674D1B6059f2C2A5AB6a5eddD53306517A89b',
    },
    validators: [
      '0xefD4e535d62EEB57581176c09a3bCE579045f4d7',
      '0x22B5096970642aA215df2186af89acff4E6F5ea0',
      '0xb77700814e88F91562BB28966755C8d6e78Dd293',
      '0x9aEb921b011C64c7c02890901d00A7FF4c87C26B',
      '0x6951F85c1Ca686529FF377EFD5Fcf1114d56Bebb',
      '0x02Ee5c96C3D04F327A73Ba2a475D0a2631e83598',
      '0xEb99bEde3b3BE01DB77C114e6188fD45BE62591E',
      '0x7b25bB64d32E54FdB2a2De481bE6e20fD75373AD',
      '0x0dfBCD9AF49A1ef33E480e00A637a9192FA6e393',
      '0xf4EbbcbE1B95c441e46A6E4dFAd5Aa2b35dfDE28',
    ],
  },
  {
    name: 'Bittorrent',
    id: 'bittorrent',
    icon: <BittorrentIcon />,
    explorer: 'https://bttcscan.com',
    contracts: {
      metadataOracle: '0x73F23073b4778f8Cc13a4AbD4C98269C0D47AE3C',
      metadataOracleProxy: '0x7698e538Eb7273CBbF31CDe646032fbC74C44481',
      unitMetadataOracle: '0x5468DD9B12876E8AaeDEC47b39cD42BfA2aAb1f7',
      chainlinkCompatibility: '0x6d230D2b066977b7Ae5357d4A9738a1105E2e539',
      helper: '0x3C5B93f5B070b1aFa90E1303f0BF09A48e586294',
      newParticipantsMutationsStorage: '0x39cac812f63Bd116B2FA3aCe297A5B8f5A8a6b99',
    },
    validators: [
      '0x57cBfcC3961Aac03de1B9B53E3261f508DC86B39',
      '0xf8F5e1Be75B7fe1C082280e541dF61b08aeA4811',
      '0x1f53fBABC37A3Ed6201d118e74167ce62c79AfE0',
      '0x7f2a548Bd12fAB9d21516fF3f554758a8469f773',
      '0x9b39C6328FD9F4794583a91A1ff62f793A43D1A0',
      '0x21a7B085131E48E9C50cF015ECF2c34a5D692aDf',
      '0x695bf5C30672c76c4c99Ae640F1a5eE34d9A741d',
      '0x68A03a1E98fe6C6eBfA1D3dC3fAf7FdC3544a2e2',
      '0xA8E56777D80C6a10dA4748a3116aFfB19A90Bf27',
      '0x258020FF72e5A87E8c989e671739FAc6DF08565c',
    ],
  },
  {
    name: 'Cronos',
    id: 'cronos',
    icon: <CronosIcon />,
    explorer: 'https://cronoscan.com',
    contracts: {
      metadataOracle: '0x73F23073b4778f8Cc13a4AbD4C98269C0D47AE3C',
      metadataOracleProxy: '0x7698e538Eb7273CBbF31CDe646032fbC74C44481',
      unitMetadataOracle: '0x5468DD9B12876E8AaeDEC47b39cD42BfA2aAb1f7',
      chainlinkCompatibility: '0x6d230D2b066977b7Ae5357d4A9738a1105E2e539',
      helper: '0x868C815A90Ab71F8905D6aAD03D4DF04Ca4E2153',
      newParticipantsMutationsStorage: '0x77d0615CBC4F7166514791809b5815368a67170D',
    },
    validators: [
      '0xa68D0904e2889b35FA4E93ab2Fe7AD5781200aeD',
      '0x299a0fB52b83A5C3a936d36B7f93d7B74e819b8D',
      '0xa2c294F6d6d8b461B886F833325C7CFb45d99c94',
      '0x4a72309C1b274B947DAaEBa3294EfA967581d48B',
      '0x257e40889f732972584da355dE408460cE0b194D',
      '0xA27D5b128Cc15bC200aED127E3F536Bc3D2eA9b1',
      '0xcAFd15815F23BF87b3618830ED7926aB823672a2',
      '0x5978197D5B465cA42494B5DdF5Ca48DC2e2F7240',
      '0xb48A79fcAEbD57adA4C109319f57146A73E4c542',
      '0x5BDDe9db23dA42b61e30Cc13519eE532952516e7',
    ],
  },
  {
    name: 'Klaytn',
    id: 'klaytn',
    icon: <KlaytnIcon />,
    explorer: 'https://scope.klaytn.com',
  },
  {
    name: 'Aurora',
    id: 'aurora',
    icon: <AuroraIcon />,
    explorer: 'https://aurorascan.dev',
  },
  {
    name: 'Dogechain',
    id: 'dogechain',
    icon: <DogechainIcon />,
    explorer: 'https://explorer.dogechain.dog',
  },
  {
    name: 'Multidata',
    id: 'multidata',
    icon: <MultidataLogo />,
    explorer: LINKS.explorer,
    contracts: {
      metadataOracle: '0x73F23073b4778f8Cc13a4AbD4C98269C0D47AE3C',
      metadataOracleProxy: '0x5468DD9B12876E8AaeDEC47b39cD42BfA2aAb1f7',
      unitMetadataOracle: '0xBFdE907D3AcC55501dD47a55Acfec010119D86e0',
      chainlinkCompatibility: '0x8aE5440754453B2b5768a4C1EAb778D5FeBE45Ad',
      helper: '0x7698e538Eb7273CBbF31CDe646032fbC74C44481',
      newParticipantsMutationsStorage: '0x6d230D2b066977b7Ae5357d4A9738a1105E2e539',
    },
    validators: [
      '0xDe1f0F718C357A483C2690f50b7F8997bD470Acc',
      '0x90AA525b1C6Fe2C04275a7bCb922ab5D51b05E23',
      '0xf6212AabFd3F50B56845Ffd735527F0A7a32255e',
      '0xeD82Ed30C72B4e90E823BeF1215502Acc50d1715',
      '0x7e54C7B3D1935eB11dc448E7CEAEa3969F27A7C3',
      '0x23cf0D29FF73f27ee0EBfc788f61f5f5d931ebE2',
      '0x16C5C527cbdB9466249a9E1A3358971C01ce4189',
      '0xcF869A56A684F215C0Aaec31f63a3C61cca8A185',
      '0xAcb6b0C8c0510BBae8FC067F15D556211fDd942b',
      '0x7f7CAD249E971199817ba8e4f9266AAaC9Df2B1D',
    ],
  },
  {
    name: 'Linea',
    id: 'linea',
    explorer: 'https://explorer.goerli.linea.build/',
    icon: <LineaIcon />,
    contracts: {
      metadataOracle: '0x73F23073b4778f8Cc13a4AbD4C98269C0D47AE3C',
      metadataOracleProxy: '0x7698e538Eb7273CBbF31CDe646032fbC74C44481',
      unitMetadataOracle: '0x5468DD9B12876E8AaeDEC47b39cD42BfA2aAb1f7',
      chainlinkCompatibility: '0x6d230D2b066977b7Ae5357d4A9738a1105E2e539',
      helper: '0x0c0d81eB00183c15C0F52306d5251994a38708be',
      newParticipantsMutationsStorage: '0x097a3E19C20c090e762C6b1b3eE2E58c4e1Ee2c8',
    },
    validators: [
      '0x9D08B8858E46AD5137F4a9149E5FE6a6a27EA256',
      '0x173C09F1c9E9C53BfFb497bCaC1647642172Ea43',
      '0x1DbCE7E274dDCb59619d443920813677267eE364',
      '0x140445272C1F721F4c5AAE9c20e4dc03B6248C84',
      '0x0A6A2C4d30A6Da07C3D4dc4CA694B2A1b3A04021',
      '0xe2FB4c64a873d566D2b3A6CC8CBf358241D914F0',
      '0x25f2c67D16BfF2170380162Ec0Fc9B93c14851eB',
      '0x402332f8fee262b7d37C042FEF1a5B032299C06D',
      '0xDcb109F3D67AC40703fB9d4535d6a0fe29f8436b',
      '0x5AdD8Cf2d5137fBEf76e8339fb4e21a510a6c505',
    ],
  },
].sort((a, b) => {
  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;
  return 0;
}) as ChainInfo[];

export type ChainInfo = {
  name: string;
  id: ChainName;
  icon?: React.ReactNode;
  explorer: string;
  validators?: string[];
  contracts?: {
    metadataOracle: string;
    metadataOracleProxy: string;
    unitMetadataOracle: string;
    chainlinkCompatibility: string;
    helper: string;
    newParticipantsMutationsStorage: string;
  };
};

export const SUPPORTED_NETWORKS = CHAINS.map((chain) => chain.id).filter(
  (item) => !(['syscoin', 'boba', 'aurora'] as ChainName[]).includes(item),
);
