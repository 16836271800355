import {AssetType} from '../types/Asset';

export function getAssetTypeByName(name: string): AssetType {
  if (name.length === 42) return 'tokens';
  if (['ETH', 'BTC', 'SYS'].includes(name)) return 'tokens';
  if (name.includes('Stock::')) return 'stocks';
  if (name.includes('Bond::')) return 'bonds';
  if (name.includes('Currency::')) return 'currencies';
  return 'commodities';
}
