import {FC, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {blockscoutColor} from 'src/views/blockscout/colors';
import {ChainName} from 'src/types/MetadataApi';
import {useCurrentChainsList} from 'src/hooks/useCurrentChainsList';

const SelectorContainer = styled.div`
  position: relative;
  padding: 10px 12px;
  width: max-content;

  @media screen and (max-width: 991px) {
    padding-left: 0;
  }
`;

const Dropdown = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #828ba0;
  cursor: pointer;
  ::after {
    content: '';
    display: inline-block;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
    border-top: 0.3em solid;
    margin-left: 0.71em;
  }
  :hover {
    color: #333;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 45px;
  min-width: 160px;
  border-radius: 0 0 2px 2px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  background: ${blockscoutColor.white};
  color: #212529;
  z-index: 1000;

  a,
  span {
    color: #333;
    font-size: 12px;
    padding: 10px 20px;
    text-decoration: none;
    display: block;
    width: 100%;
    cursor: pointer;
  }
`;

export const NetworkSelector: FC<
  {currentChain?: ChainName} & React.HTMLAttributes<HTMLDivElement>
> = ({currentChain, ...props}) => {
  const [showMenu, setShowMenu] = useState(false);
  const assets = useCurrentChainsList();
  const chains = useMemo(() => assets?.filter((asset) => asset.id !== 'bobhub'), [assets]);

  function onCloseMenu() {
    setShowMenu(false);
  }

  function dropdownHandler(e: React.MouseEvent) {
    e.stopPropagation();
    setShowMenu(!showMenu);
  }

  useEffect(() => {
    if (!showMenu) return;

    document.addEventListener('click', onCloseMenu);

    return () => document.removeEventListener('click', onCloseMenu);
  }, [showMenu]);

  return (
    <SelectorContainer {...props}>
      <Dropdown onClick={dropdownHandler}>Networks</Dropdown>
      {showMenu && (
        <DropdownMenu>
          {chains?.map((chain) =>
            chain.id === currentChain ? (
              <span key={chain.id}>{chain.name}</span>
            ) : (
              <a key={chain.id} href={`/v2/${chain.id}`}>
                {chain.name}
              </a>
            ),
          )}
        </DropdownMenu>
      )}
    </SelectorContainer>
  );
};
