import {StyledListItem} from '../styled';

export const Roadmap = () => (
  <ol>
    <StyledListItem>Factory of user-defined oracles</StyledListItem>
    <StyledListItem>
      API for historical data
      <ul>
        <StyledListItem>as an additional exporter</StyledListItem>
        <StyledListItem>daily logs - to a S3 bucket</StyledListItem>
      </ul>
    </StyledListItem>
    <StyledListItem>
      Liquidity control.
      <ul>
        <StyledListItem>
          <code>Uniswap*Feed</code> : don’t produce a quote if the pool is illiquid.
        </StyledListItem>
        <StyledListItem>
          the same for <code>Curve*</code>
        </StyledListItem>
        <StyledListItem>control for CEXes?</StyledListItem>
      </ul>
    </StyledListItem>
    <StyledListItem>Multi-party.</StyledListItem>
    <StyledListItem>More tests for feeds.</StyledListItem>
    <StyledListItem>
      Use type <code>2</code> Ethereum transactions on Ethereum mainnet.
    </StyledListItem>
    <StyledListItem>
      Contracts v2.
      <ul>
        <StyledListItem>
          externally set epoch timestamps (plus: for replay attack protection). Done in multiparty.
        </StyledListItem>
        <StyledListItem>
          <code>setPrices+updateDeltas</code> combined function. Done in multiparty.
        </StyledListItem>
        <StyledListItem>
          <code>epoch+baseprices+assets+...</code> combined getter. Done in multiparty.
        </StyledListItem>
        <StyledListItem>
          increase the compiler runs parameter, but check the effects on gas and contract sizes
        </StyledListItem>
        <StyledListItem>
          can <code>AggregatorShim</code> be replaced with lightweight proxies a la in Wrapped SSLP?
        </StyledListItem>
        <StyledListItem>ndividual update timestamps for assets</StyledListItem>
        <StyledListItem>doc-comments</StyledListItem>
      </ul>
    </StyledListItem>
    <StyledListItem>
      Computations.
      <ul>
        <StyledListItem>
          use <code>Decimal</code> everywhere
        </StyledListItem>
        <StyledListItem>
          remove any <code>float</code> intermediaries
        </StyledListItem>
      </ul>
    </StyledListItem>
    <StyledListItem>
      Split the <code>neworacles</code> monolith into a set of reusable libraries (packages) and
      services (in dedicated repos)
    </StyledListItem>
    <StyledListItem>
      Multi-tiered liquidity-price results, e.g.:{' '}
      <code>[($100_000, $0.5), ($1000_000, $0.4), ($10_000_000, $0.2)]</code>.
    </StyledListItem>
    <StyledListItem>
      Don’t require intermediate feed naming in config.
      <ul>
        <StyledListItem>
          e.g., write Mul(UniswapFeed(…), ETHUSD) without a name for UniswapFeed.
        </StyledListItem>
      </ul>
    </StyledListItem>
    <StyledListItem>
      Automatic feed detection & configuration. For fucking everything.
    </StyledListItem>
    <StyledListItem>
      Super integration tests (?Smoke-tests)
      <ul>
        <StyledListItem>
          on push to master run instance with the same oracles config as in production
        </StyledListItem>
        <StyledListItem>write prices to testnet</StyledListItem>
        <StyledListItem>
          check that prices were written and maybe compare them to cmc
        </StyledListItem>
      </ul>
    </StyledListItem>
  </ol>
);
