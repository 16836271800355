import {FC, RefObject, useEffect} from 'react';
import {useActualRef} from 'src/hooks/useActualRef';

type Props = {
  onIntersecting: () => void;
  intersectingRef: RefObject<HTMLDivElement> | null;
  observerConfig?: IntersectionObserverInit;
  children?: React.ReactNode;
};

export const IntersectionBlock: FC<Props> = ({
  children,
  intersectingRef,
  onIntersecting,
  observerConfig,
  ...props
}) => {
  const onIntersectingRef = useActualRef(onIntersecting);

  useEffect(() => {
    if (intersectingRef && intersectingRef.current) {
      const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onIntersectingRef.current();
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersection, observerConfig);
      observer.observe(intersectingRef.current);
      return () => observer.disconnect();
    }
  }, [intersectingRef, observerConfig, onIntersectingRef]);

  return (
    <div ref={intersectingRef} {...props}>
      {children}
    </div>
  );
};
