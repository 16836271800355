import {useParams} from 'react-router-dom';
import {ChainName} from 'src/types/MetadataApi';
import {BlockscoutNetworks} from 'src/views/blockscout/BlockscoutNetworks';
import {BlockscoutPrices} from 'src/views/blockscout/BlockscoutPrices';
import {BlockscoutValidators} from 'src/views/blockscout/BlockscoutValidators';
import {Card, Container} from 'src/views/blockscout/styled';
import {BlockscoutFooter} from 'src/views/blockscout/components/BlockscoutFooter';
import {BlockscoutHeader} from 'src/views/blockscout/components/BlockscoutHeader';

export const BlockscoutNetworkId = () => {
  const {networkId} = useParams<{
    networkId: ChainName;
  }>();

  return (
    <div style={{backgroundColor: '#fbfafc'}}>
      <BlockscoutHeader networkId={networkId} />
      <BlockscoutNetworks selectedId={networkId} style={{padding: '0 16px'}} />
      <Container>
        <Card>
          <BlockscoutPrices />
        </Card>
        <Card>
          <BlockscoutValidators />
        </Card>
      </Container>
      <BlockscoutFooter />
    </div>
  );
};
