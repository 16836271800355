import {StyledListItem, StyledSubtitle, StyledText} from '../styled';

export const PrivelegedFunctions = () => (
  <>
    <StyledSubtitle>Participant</StyledSubtitle>
    <StyledText>
      Create calldata for a privileged transaction, e.g.:{' '}
      <code>addAsset(asset, currentPrice, salt, deadline)</code>.
    </StyledText>
    <ul>
      <StyledListItem>
        Multisignature case: sign the message <code>Vote(bytes calldata)</code> and transact{' '}
        <code>vote(calldata, signature)</code> to the contract. As soon as the quorum of unique
        valid signatures is present (from the current mainnet contract viewpoint), transact to the
        mainnet.
      </StyledListItem>
      <StyledListItem>
        Threshold signature case: sign the message <code>Vote(bytes calldata)</code>.
      </StyledListItem>
    </ul>
    <StyledText>
      Note that each particular transaction calldata must include a unique salt to prevent replay
      attacks. Additionally, a deadline timestamp may be included to limit the TTL of the action
      proposed.
    </StyledText>
    <StyledText>
      Also, note that the privileged call signatures gathering requires scanning the entire side
      network contract lifetime for corresponding events.
    </StyledText>

    <StyledSubtitle>The side network contract</StyledSubtitle>
    <ul>
      <StyledListItem>
        Multisignature case: <code>vote(bytes calldata, signature)</code>: emit an event for a valid
        participant.
      </StyledListItem>
      <StyledListItem>Threshold signature case: TBD</StyledListItem>
    </ul>

    <StyledSubtitle>The main network contract</StyledSubtitle>
    <StyledText>
      Check the signatures. Check the deadline, if any. Check that the given salt is not used yet;
      mark the salt as used. Exec the call (via self-call, perhaps).
    </StyledText>
  </>
);
