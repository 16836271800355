import React from 'react';
import {knowledgeCenter} from '../knowledgeCenter';
import {StyledListItem, StyledSubtitle} from '../styled';

export const Connectors = () => {
  const connectors = knowledgeCenter.connectors;
  return (
    <>
      {connectors.map((connector, index) => (
        <React.Fragment key={index}>
          <StyledSubtitle>{connector.title}</StyledSubtitle>
          <ul>
            {connector.list.map((item, i) => (
              <StyledListItem key={i}>{item}</StyledListItem>
            ))}
          </ul>
        </React.Fragment>
      ))}
    </>
  );
};
