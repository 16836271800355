import {SectionWrap, StyledSubtitle} from 'src/views/home/styled';
import {IconsContainer} from 'src/views/home/components/SupportedNetworks/styled';
import {SourceCard} from 'src/views/home/components/Sources/styled';
import {LINKS} from 'src/constants/staticValues';
import {ReactComponent as BoosterIcon} from 'src/assets/icons/booster.svg';

export const Integration = () => (
  <SectionWrap>
    <StyledSubtitle style={{maxWidth: '100%'}}>Integrations</StyledSubtitle>
    <IconsContainer>
      <a
        href={LINKS.booster}
        target="_blank"
        rel="noreferrer"
        style={{color: 'currentcolor', textDecoration: 'none'}}
      >
        <SourceCard>
          <BoosterIcon />
        </SourceCard>
      </a>
    </IconsContainer>
  </SectionWrap>
);
