import styled from 'styled-components';
import {Container} from 'src/views/blockscout/styled';
import {CHAINS} from 'src/constants/chains';
import {ReactComponent as Logo} from 'src/assets/logo.svg';
import {blockscoutColor} from 'src/views/blockscout/colors';

const FooterContainer = styled.footer`
  position: relative;
  margin: 0;
  padding: 40px 0;
  background: ${blockscoutColor.main};
  color: #d4d9f2;
  font-size: 12px;
  line-height: 1.67;

  p {
    margin: 0 0 25px;
    padding: 0 20px 0 0;
    font-size: 13px;
    font-family: Nunito, Arial, sans-serif;
  }

  a {
    text-decoration: none;
    color: currentColor;
  }
`;

const Row = styled.div`
  max-width: 25%;
  margin-top: 30px;
  @media screen and (max-width: 991px) {
    max-width: 100%;
  }
`;

export const BlockscoutFooter = () => {
  const multidataExplorer = CHAINS.find((chain) => chain.id === 'bobhub')?.explorer;
  return (
    <FooterContainer>
      <Container style={{paddingTop: 0}}>
        <a href={multidataExplorer}>
          <Logo style={{color: '#fff', maxWidth: '170px'}} />
        </a>
        <Row>
          <p>
            Chunk is a single-focused blockchain that integrates an oracle mechanism. It efficiently
            stores and delivers a vast amount of real-world data, enabling you to create and execute
            smart contracts with confidence.
          </p>
        </Row>
      </Container>
    </FooterContainer>
  );
};
