import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {CHAINS} from '../constants/chains';
import {assetsStore} from '../store/assetsStore';
import {AssetsStoreMap, AssetType, Version} from '../types/Asset';
import {ChainName} from '../types/MetadataApi';
import {useSubject} from './useSubject';

export const useNetwork = (
  id: string | undefined,
  currentTab: AssetType,
  version: Version | undefined,
) => {
  const assets = useSubject<AssetsStoreMap>(assetsStore.assets);
  const storeInitialized = useSubject(assetsStore.initialized);
  const [network, setNetwork] = useState<ChainName | null>(null);
  const navigate = useNavigate();
  const namesAllowed = CHAINS.map((chain) => chain.id);

  useEffect(() => {
    assetsStore.init();
  }, []);

  useEffect(() => {
    const waitForInitialization = !storeInitialized && id && namesAllowed.includes(id as ChainName);

    if (waitForInitialization || !version || !assets[version]) return;
    if (!id || !Object.keys(assets[version]).includes(id as ChainName)) {
      navigate('/');
      return;
    }
    setNetwork(id as ChainName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, assets, version]);

  const networkAssets = network && version ? assets[version][network] : null;
  const chainParams = CHAINS.find((chain) => chain.id === id);
  const networkName = chainParams?.name;
  const networkExplorer = chainParams?.explorer;
  const currentContractAddress =
    network && version ? (assets[version][network][currentTab]?.address as string) : null;

  return {networkAssets, networkName, currentContractAddress, networkExplorer};
};
