export const color = {
  green: '#C3F950',
  gray: '#F0F0F0',
  lightGray: '#F5F5F5',
  darkGray: '#BDBDBD',
  grayText: '#878787',
  lightBlack: '#CCCCCC',
  black: '#000',
  white: '#fff',
  blackText: '#4d4d4d',
  darkBlack: '#171717',
  grey: '#f8f8f8',
};

export const breakpoint = {
  phone: '500px',
  tablet: '768px',
  desktop: '1170px',
  hd: '1440px',
  fullHd: '1920px',
};

export const fontFamily = {
  inter: 'Circe Regular',
  sans: 'General Sans',
};
