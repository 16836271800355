import styled, {css} from 'styled-components';
import {breakpoint, color, fontFamily} from 'src/components/UI/theme';
import {Link} from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1170px;
  min-width: 280px;
  align-items: stretch;
  flex-grow: 1;
  margin: 7rem auto 0;
  padding: 0px 3rem;
  min-height: calc(100vh - 30rem);
`;

export const SectionWrap = styled.div`
  margin: 5rem 0;
  @media (min-width: ${breakpoint.tablet}) {
    margin: 12rem 0;
  }
`;

export const StyledSubtitle = styled.h2`
  font-family: ${fontFamily.sans};
  font-size: 6rem;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 7rem;

  @media (min-width: ${breakpoint.tablet}) {
    font-size: 8rem;
  }
`;

export const StyledText = styled.p`
  margin: 0;
  font-size: 2.5rem;
  font-family: ${fontFamily.inter};
  line-height: 1.5;
`;

export const Card = styled.div`
  background: ${color.gray};
  border-radius: 1.25rem;
  padding: 2rem;
  min-height: 20rem;
  :hover {
    transform: scale(1.02);
  }
`;

export const RoundButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${color.darkBlack};
  font-size: 2.25rem;
  width: 100%;
  max-width: 22.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background: ${color.white};
  line-height: 1.5;
  border-radius: 3.25rem;
  border: 0.125rem solid ${color.darkBlack};
  :hover {
    color: ${color.white};
    background: ${color.darkBlack};
  }
`;

export const RoundLink = styled(Link)`
  ${RoundButton};
  text-decoration: none;
  margin: 0 auto;
  margin-top: 10rem;
`;

export const RoundLinkExternal = styled.a`
  ${RoundButton};
  text-decoration: none;
  margin: 0 auto;
  margin-top: 10rem;
`;

export const AccentedNumber = styled.span`
  font-family: ${fontFamily.sans};
  font-weight: bold;
  font-size: 10rem;
`;

export const MetricsContainer = styled.div`
  display: grid;
  grid-gap: 4rem;
  font-size: 2.5rem;
  line-height: 1.5;
  @media (min-width: ${breakpoint.desktop}) {
    grid-template-columns: auto 1fr;
  }
`;

export const DataItem = styled.div`
  display: flex;
  align-items: center;
  img,
  svg {
    width: 3rem;
    height: auto;
    margin-right: 4rem;
  }
`;

export const StyledSectionWrap = styled(SectionWrap)`
  @media (min-width: ${breakpoint.desktop}) {
    p {
      max-width: 80%;
    }
  }
`;

export const NumberSection = styled(SectionWrap)`
  display: grid;
  justify-items: start;
  grid-gap: 2rem;
  font-size: 2.5rem;
  @media (min-width: ${breakpoint.desktop}) {
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
  }
`;
