import {breakpoint} from 'src/components/UI/theme';
import styled from 'styled-components';
import {Card} from 'src/views/home/styled';

export const StyledIcon = styled(Card).withConfig({
  shouldForwardProp: (prop) => !['isMultidata'].includes(prop),
})<{isMultidata?: boolean}>(
  ({isMultidata}) => `
  display: grid;
  grid-gap: 2rem;
  justify-items: center;
  align-items: center;
  width: 100%;
  svg {
    width: auto;
    max-width: ${isMultidata ? '160px' : '180px'};
    height: 10rem;
    fill: transparent;
  }

  @media (max-width: 480px) {
    svg {
      max-width: 160px;
    }
  }

  @media (max-width: 375px) {
    svg {
      max-width: ${isMultidata ? '100px' : '120px'};
    }
  }
`,
);

export const IconsContainer = styled.div`
  display: grid;
  grid-gap: 4rem;
  margin-top: 2rem;

  @media (min-width: ${breakpoint.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${breakpoint.desktop}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
