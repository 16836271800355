import {StyledListItem, StyledSubtitle, StyledText} from '../styled';

export const ParticipantSynchronization = () => (
  <>
    <StyledText>
      Participant set synchronization (between the contracts) is desirable for participants
      filtering, but not required. Is done as a privileged function (see above), but with extra work
      performed.
    </StyledText>
    <StyledText>
      <i>
        Comment: the on-chain participant set management code may be shared between the contracts.
      </i>
    </StyledText>

    <StyledSubtitle>Participant</StyledSubtitle>
    <StyledText>
      Participants produce a privileged function signature(s). Participants don’t initiate a new
      privileged function call on the side network until the current fully signed call is mined on
      the mainnet. Participants don’t bypass the side network contract.
    </StyledText>

    <StyledSubtitle>The side network contract</StyledSubtitle>
    <ul>
      <StyledListItem>
        Multisignature case: detecting set modification calls (<code>addParticipant</code>,{' '}
        <code>setQuorum</code>, etc) via selectors, applying them to self (via self-call, perhaps),
        and emitting an event when the quorum is present.
      </StyledListItem>
      <StyledListItem>
        Threshold signature case: the current owner signs{' '}
        <code>
          NewOwner(address newOwner,uint quorum,address[] participants,uint salt,uint deadline)
        </code>
        , the contract extracts participants.
      </StyledListItem>
    </ul>
  </>
);
