import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {MainLayout} from 'src/layout/MainLayout';
import {Notification} from './components/UI/Notification';
import {GlobalStyles} from './layout/GlobalStyles';
import {BlockscoutNetworkId} from './views/blockscout/BlockscoutNetworkId';
import {BlockscoutNetworks} from './views/blockscout/BlockscoutNetworks';
import {BlockscoutPrices} from './views/blockscout/BlockscoutPrices';
import {BlockscoutValidators} from './views/blockscout/BlockscoutValidators';
import {Home} from './views/home';
import {KnowledgeCenter} from './views/knowledge-center';

export const App: React.FC = () => (
  <>
    <GlobalStyles />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="networks" element={<BlockscoutNetworks />} />
          <Route path="knowledge-center" element={<KnowledgeCenter />} />
          <Route path="/:version">
            <Route path=":networkId">
              <Route index element={<BlockscoutNetworkId />} />
              <Route path="validators" element={<BlockscoutValidators />} />
              <Route path="prices" element={<BlockscoutPrices />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
    <Notification />
  </>
);
