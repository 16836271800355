import styled from 'styled-components';
import {Card} from 'src/views/home/styled';
import {breakpoint} from 'src/components/UI/theme';

export const SourceCard = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  line-height: 1.5;
  white-space: nowrap;
  svg {
    width: 20rem;
    height: 10rem;
  }
`;

export const SourcesContainer = styled.div`
  display: grid;
  grid-gap: 4rem;
  margin-top: 5rem;
  font-weight: bold;

  @media (min-width: ${breakpoint.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${breakpoint.desktop}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
