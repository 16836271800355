import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useNetwork} from 'src/hooks/useNetwork';
import {AssetType, Version} from 'src/types/Asset';
import {ChainName} from 'src/types/MetadataApi';
import {formatNumber} from 'src/utils/formatNumber';
import {
  Wrap,
  ContainerHeader,
  PaginationWrap,
  RightBlock,
  Row,
  StyledTitle,
  Term,
} from 'src/views/blockscout/styled';
import {StatusPoint} from 'src/views/blockscout/components/StatusPoint';
import {BlockscoutButton} from './components/BlockscoutButton';
import {useFilterByType} from 'src/hooks/useFilterByType';
import {BlockscoutPagination} from './components/BlockscoutPagination';
import {mainThemeColor} from './colors';
import moment from 'moment';

export const BlockscoutPrices = () => {
  const {networkId, version} = useParams<{
    networkId: ChainName;
    version: Version;
  }>();
  const [currentTab, setCurrentTab] = useState<AssetType>('tokens');
  const {networkAssets} = useNetwork(networkId, currentTab, version);
  const {filteredAssets, filterByTypeOptions, filterByType, setFilterByType} = useFilterByType(
    currentTab,
    networkAssets,
  );
  const elementsPerPage = 18;
  const [currentPage, setCurrentPage] = useState(1);

  const themeColor =
    networkId && mainThemeColor[networkId] ? mainThemeColor[networkId] : mainThemeColor.default;

  const assets = networkAssets?.tokens;

  const lastUpdated = assets
    ? moment(assets.updated * 1000)
        .utc()
        .format('LLL')
    : '';

  useEffect(() => {
    setFilterByType('All');
  }, [currentTab, networkId, setFilterByType]);

  useEffect(() => {
    setCurrentTab('tokens');
  }, [networkId]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filterByType]);

  const postMessage = () => {
    window.parent.postMessage({pricesHeight: `${window.document.body.scrollHeight}px`}, '*');
  };

  useEffect(() => {
    postMessage();
  }, [filterByTypeOptions]);

  useEffect(() => {
    window.addEventListener('resize', postMessage);

    return () => window.removeEventListener('resize', postMessage);
  }, []);

  return (
    <Wrap>
      <ContainerHeader>
        <StyledTitle style={{marginTop: '5px', marginRight: '15px'}}>Recent data feeds</StyledTitle>
        {currentTab === 'tokens' && (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-end',
              marginBottom: '16px',
              marginRight: '-5px',
            }}
          >
            {filterByTypeOptions
              .filter((elem) => !elem.disable)
              .map((el) => (
                <BlockscoutButton
                  key={el.label}
                  themeColor={themeColor}
                  active={filterByType === el.label}
                  onClick={el.onClick}
                  title={el.label === 'All' ? 'All Classes' : el.label}
                  style={{margin: '5px'}}
                />
              ))}
          </div>
        )}
      </ContainerHeader>
      <PaginationWrap>
        {filteredAssets && filteredAssets?.length > elementsPerPage && (
          <BlockscoutPagination
            currentPage={currentPage}
            maxPages={Math.ceil(filteredAssets.length / elementsPerPage)}
            setCurrentPage={setCurrentPage}
          />
        )}
      </PaginationWrap>
      {filteredAssets &&
        filteredAssets
          .slice((currentPage - 1) * elementsPerPage, currentPage * elementsPerPage)
          .map((token) => (
            <Row key={token.address}>
              <Term>{token.symbol}</Term>
              <RightBlock>
                <div style={{display: 'flex'}}>
                  <StatusPoint />
                  <div>
                    <span>${formatNumber.getDisplayFromQ112(token.price, 0, 2)}</span>
                    <span style={{paddingLeft: '5px'}}>
                      {token.metric?.currency === 'PCT' ? '%' : token.metric?.currency || 'USD'}
                    </span>
                  </div>
                </div>
                <span style={{lineHeight: '21px'}}>{lastUpdated} UTC</span>
              </RightBlock>
            </Row>
          ))}
    </Wrap>
  );
};
