import React from 'react';
import {ReactNode} from 'react';

const TEMPLATE_VAR_RE = /\{\{(.+?)\}\}/;

const t9nInterpolate = <T>(
  template: string,
  vars: Record<string, T>,
  append: (chunk: T | string) => void,
) => {
  let strTail = template;

  while (true) {
    const match = strTail.match(TEMPLATE_VAR_RE);

    if (!match || match.index === undefined) return append(strTail);

    const [chunkToReplace, name] = match;
    const nextStartIndex = match.index + chunkToReplace.length;

    if (name in vars) {
      const elem = vars[name];
      const chunk = strTail.slice(0, match.index);
      append(chunk);
      append(elem);
    } else {
      // leave unchanged
      append(strTail.slice(0, nextStartIndex));
    }

    strTail = strTail.slice(nextStartIndex);
  }
};

export const t9nRenderStr = (template: string, vars: Record<string, string>): string => {
  let acc = '';
  t9nInterpolate(template, vars, (chunk: string) => {
    acc = acc.concat(chunk);
  });
  return acc;
};

export const t9nRenderJsx = (template: string, vars: Record<string, ReactNode | string>) => {
  const acc: (string | ReactNode)[] = [];
  t9nInterpolate(template, vars, (chunk: ReactNode | string) => {
    acc.push(
      React.isValidElement(chunk) ? React.cloneElement(chunk, {key: `${acc.length}`}) : chunk,
    );
  });
  return React.createElement(React.Fragment, {}, ...acc);
};
