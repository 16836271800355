import {SectionWrap, StyledSubtitle, StyledText} from 'src/views/home/styled';
import {SubTitle} from 'src/views/home/components/Discover/styled';
import styled from 'styled-components';
import {breakpoint} from 'src/components/UI/theme';
import {SUPPORTED_NETWORKS} from 'src/constants/chains';

const features = [
  {
    title: 'Comprehensive Metrics:',
    text: 'Access a wide variety of data points, including token prices, stock and commodity prices, currency exchange rates, bond yields, macroeconomic indicators, and sensor-generated time series data.',
  },
  {
    title: 'Expansive Network Coverage:',
    text: `Benefit from data updates across ${SUPPORTED_NETWORKS.length} networks for a comprehensive and reliable information source.`,
  },
  {
    title: 'Diverse Data Sources:',
    text: 'Leverage the decentralized oracle mechanism that aggregates price feeds from multiple on-chain and off-chain sources like decentralized exchanges (DEXs), centralized exchanges (CEXs), and third-party APIs.',
  },
  {
    title: 'Trustworthy Signatures:',
    text: 'Rely on the secure transmission of data feeds on-chain through a single, verifiable multi-party threshold signature.',
  },
];

const FeaturesWrap = styled.div`
  display: grid;
  grid-gap: 2rem 4rem;

  @media (min-width: ${breakpoint.tablet}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Features = () => (
  <SectionWrap>
    <StyledSubtitle>Explore Chunk's Powerful Features</StyledSubtitle>
    <FeaturesWrap>
      {features.map((feature, index) => (
        <div key={index}>
          <SubTitle style={{marginTop: '4rem'}}>{feature.title}</SubTitle>
          <StyledText style={{marginTop: '3rem'}}>{feature.text}</StyledText>
        </div>
      ))}
    </FeaturesWrap>
  </SectionWrap>
);
