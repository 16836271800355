import {Outlet, useLocation} from 'react-router-dom';
import {Footer} from 'src/layout/Footer';
import {Header} from 'src/layout/Header';

export const MainLayout = () => {
  const location = useLocation();
  const isLocationIframe = location.pathname.match(/v2|networks/);

  return (
    <>
      {!isLocationIframe && <Header />}
      <Outlet />
      {!isLocationIframe && <Footer />}
    </>
  );
};
