import React from 'react';
import {CHAINS, SUPPORTED_NETWORKS} from 'src/constants/chains';
import {knowledgeCenter} from 'src/views/knowledge-center/knowledgeCenter';
import {Card, StyledListItem, StyledSubtitle, StyledText} from '../styled';

export const AboutUs = () => {
  const {text, assets, sources} = knowledgeCenter.aboutUs;
  const multidataChain = CHAINS.find((chain) => chain.id === 'bobhub');

  return (
    <>
      <StyledText>{text}</StyledText>

      <a
        href="/images/architecture.svg"
        target="_blank"
        rel="noopener noreferrer"
        title="open full size image"
      >
        <img
          src="/images/architecture.svg"
          alt="Overall Architecture"
          style={{width: '100%', marginBottom: '2rem'}}
        />
      </a>

      <StyledText>
        <i>Currently,</i> a beta version of the Chunk network (V1) quotes 1000+ assets on:
      </StyledText>
      <ul>
        <StyledListItem>{multidataChain?.name}</StyledListItem>
        {CHAINS.filter((chain) => SUPPORTED_NETWORKS.includes(chain.id)).map((chain) => (
          <StyledListItem key={chain.id}>{chain.name}</StyledListItem>
        ))}
      </ul>

      <StyledSubtitle>Supported types of assets:</StyledSubtitle>
      <ul>
        {assets.map((asset) => (
          <StyledListItem key={asset.name}>{asset.name}</StyledListItem>
        ))}
        <StyledListItem>Stocks, Bonds, Currencies, Commodities on Chain</StyledListItem>
      </ul>
      <Card>
        INJ, JASMY, MASK, MAGIC, MATIC, RPL, MDT, LQTY, KEY, SHIB, TRU, UNI, USDD, MKR, AGIX, WAVES,
        WOO, YFI, USDT, WBNB, USDC, PAXG, QNT, RNDR, LDO, WETH, WBTC, 3Crv, DAI, AAVE, ACH, LINK,
        CHZ, PEOPLE, CRV, MANA, DODO, DUSK, IMX, SSV, SUSHI, ANKR, APE, AXS, BUSD, BMX, BTT, BLUR,
        DYDX, ENJ, ENS, FET, FRAX, FXS, GALA, GRV, HFT, SNX, HEX, HIGH, NMR, OCEAN, STG, GMT, SYN,
        GRT, SAND, JST, KAVA, GNS, NEO, XMR, NEAR, SOL, VET, VOXEL, ZEC, OP, PHB, DOT, POLYX, QTUM,
        RDNT, RVN, RIF, XRP, LTC, BTC, ALGO, APT, AR, CFX, CORE, ATOM, DOGE, ZIL, MINA, GMX, ICP,
        LUNA, AVAX, BCH, STX, ADA, EGLD, EOS, ETC, FTM, FIL, FLOW, ONE, HBAR, HOOK, ROSE, XLM, SUN,
        XTZ, RUNE, TRX
      </Card>

      <StyledSubtitle>Sources of data</StyledSubtitle>
      <Card>{sources}</Card>
    </>
  );
};
