import axios, {AxiosPromise} from 'axios';
import {TrustwalletTokenList} from '../types/Trustwallet';

function getTokensList(): AxiosPromise<TrustwalletTokenList> {
  return axios.get(
    `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/tokenlist.json`,
  );
}

export const trustwallet = {
  getTokensList,
};
