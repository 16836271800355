import {FC} from 'react';
import {IntersectionBlock} from 'src/components/IntersectionBlock';
import {ChainInfo} from 'src/constants/chains';
import {LINKS} from 'src/constants/staticValues';
import {StyledListItem, StyledSubtitle, StyledTitle} from 'src/views/knowledge-center/styled';

const observerConfig = {rootMargin: '0px 0px -75% 0px'};

export const SupportedNetworks: FC<{
  chains: ChainInfo[];
  refs: React.RefObject<HTMLDivElement>[];
  setCurrentSection: (index: number) => void;
}> = ({chains, refs, setCurrentSection}) => (
  <>
    {chains.map((chain, index) => (
      <IntersectionBlock
        key={chain.id}
        onIntersecting={() => setCurrentSection(index)}
        intersectingRef={refs[index]}
        observerConfig={observerConfig}
      >
        <StyledTitle>{chain.name}</StyledTitle>
        {chain.contracts && (
          <table style={{margin: '3.5rem 0'}}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Contract Address</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>MetadataOracle</td>
                <td>
                  <a
                    href={`${chain.explorer}/address/${chain.contracts.metadataOracle}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chain.contracts.metadataOracle}
                  </a>
                </td>
              </tr>
              <tr>
                <td>MetadataOracleProxy</td>
                <td>
                  <a
                    href={`${chain.explorer}/address/${chain.contracts.metadataOracleProxy}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chain.contracts.metadataOracleProxy}
                  </a>
                </td>
              </tr>
              <tr>
                <td>UnitMetadataOracle</td>
                <td>
                  <a
                    href={`${chain.explorer}/address/${chain.contracts.unitMetadataOracle}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chain.contracts.unitMetadataOracle}
                  </a>
                </td>
              </tr>
              <tr>
                <td>ChainlinkCompatibility</td>
                <td>
                  <a
                    href={`${chain.explorer}/address/${chain.contracts.chainlinkCompatibility}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chain.contracts.chainlinkCompatibility}
                  </a>
                </td>
              </tr>
              <tr>
                <td>Helper</td>
                <td>
                  <a
                    href={`${LINKS.explorer}/address/${chain.contracts.helper}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chain.contracts.helper}
                  </a>
                </td>
              </tr>
              <tr>
                <td>NewParticipantsMutationsStorage</td>
                <td>
                  <a
                    href={`${LINKS.explorer}/address/${chain.contracts.newParticipantsMutationsStorage}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chain.contracts.newParticipantsMutationsStorage}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {chain.validators && (
          <>
            <StyledSubtitle>Validators:</StyledSubtitle>
            <ul style={{marginBottom: '6rem'}}>
              {chain.validators.map((validator) => (
                <StyledListItem key={validator}>
                  <a
                    href={`${chain.explorer}/address/${validator}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {validator}
                  </a>
                  {' ('}
                  <a
                    href={`${LINKS.explorer}/address/${validator}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    side chain
                  </a>
                  {')'}
                </StyledListItem>
              ))}
            </ul>
          </>
        )}
      </IntersectionBlock>
    ))}
  </>
);
