import {ChainName} from 'src/types/MetadataApi';

export const blockscoutColor = {
  white: '#fff',
  gray: '#6c757d',
  grayText: '#a3a9b5',
  grayBg: '#f5f6fa',
  blackText: '#212529',
  error: '#dc3545',
  success: '#28a745',
  main: '#495057',
  accentedText: '#212529',
};

export const mainThemeColor = {
  default: '#212529',
  xdai: '#3e6957',
  etc: '#5959d8',
} as {[key in ChainName]: string} & {default: string};
