import {useEffect, useState} from 'react';
import {ReactComponent as ArrowIcon} from 'src/assets/icons/ic-select-dropdown.svg';
import {ScrollButton} from 'src/components/UI/ScrollToTop/styled';

export const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  useEffect(() => {
    const handleScrollButtonVisibility = () => {
      setIsVisible(window.pageYOffset > 300);
    };
    window.addEventListener('scroll', handleScrollButtonVisibility);

    return () => {
      window.removeEventListener('scroll', handleScrollButtonVisibility);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <ScrollButton onClick={handleScrollToTop}>
          <ArrowIcon />
        </ScrollButton>
      )}
    </>
  );
};
