import {FC, useEffect} from 'react';
import {IntersectionBlock} from 'src/components/IntersectionBlock';
import {t9nRenderJsx} from 'src/utils/dictionary';
import {Components} from './Components';
import {Connectors} from './Connectors';
import {knowledgeCenter} from '../knowledgeCenter';
import {MainnetSchedule} from './MainnetSchedule';
import {ParticipantSynchronization} from './ParticipantSynchronization';
import {PrivelegedFunctions} from './PrivelegedFunctions';
import {Roadmap} from './Roadmap';
import {Card, StyledListItem, StyledText, StyledTitle} from '../styled';
import {UpdatingOracle} from './UpdatingOracle';
import {Intro} from './Intro';

const observerConfig = {rootMargin: '0px 0px -75% 0px'};

export const Implementation: FC<{
  refs: React.RefObject<HTMLDivElement>[];
  setCurrentSection: (index: number) => void;
}> = ({refs, setCurrentSection}) => {
  const {components, participants} = knowledgeCenter;

  useEffect(() => {
    setCurrentSection(0);
  }, [setCurrentSection]);
  return (
    <>
      <IntersectionBlock
        onIntersecting={() => setCurrentSection(0)}
        intersectingRef={refs[0]}
        observerConfig={observerConfig}
      >
        <Intro />
      </IntersectionBlock>
      <IntersectionBlock
        onIntersecting={() => setCurrentSection(1)}
        intersectingRef={refs[1]}
        observerConfig={observerConfig}
      >
        <StyledTitle>{components.title}</StyledTitle>
      </IntersectionBlock>
      <Components />

      <IntersectionBlock
        onIntersecting={() => setCurrentSection(2)}
        intersectingRef={refs[2]}
        observerConfig={observerConfig}
      >
        <StyledTitle>{participants.title}</StyledTitle>
        <StyledText>{participants.paragraphs[0]}</StyledText>
        <StyledText>{participants.paragraphs[1]}</StyledText>
        <ul>
          {participants.list.map((item, index) => (
            <StyledListItem key={index}>
              {t9nRenderJsx(item, {
                code: <code>{participants.code}</code>,
                ecrecover: <code>{participants.ecrecover}</code>,
              })}
            </StyledListItem>
          ))}
        </ul>
        <StyledText>{participants.paragraphs[2]}</StyledText>
      </IntersectionBlock>

      <IntersectionBlock
        onIntersecting={() => setCurrentSection(3)}
        intersectingRef={refs[3]}
        observerConfig={observerConfig}
      >
        <StyledTitle>Message hashing & signing</StyledTitle>
      </IntersectionBlock>
      <StyledText>
        The word “message” below in the phrases like “a hash of a message” or “a signature of a
        message” means a serialized typed structured data in accordance with EIP-712.{' '}
        <code>keccak256</code> of such a message (which must also be used during signing) must be
        produced according to the standard. The message will be described with a{' '}
        <code>typeHash</code>.
      </StyledText>
      <StyledText>
        <code>DOMAIN_SEPARATOR</code> must be computed as follows:
      </StyledText>
      <Card>
        <pre>
          <code>
            {`DOMAIN_SEPARATOR = keccak256(
  abi.encode(
    keccak256('EIP712Domain(string name,string version,uint256 chainId,address verifyingContract)'),
    keccak256(bytes("Metadata.Multiparty.Protocol")),
    keccak256(bytes("1")),
    chainid,
    oracleContractAddress
));`}
          </code>
        </pre>
      </Card>

      <IntersectionBlock
        onIntersecting={() => setCurrentSection(4)}
        intersectingRef={refs[4]}
        observerConfig={observerConfig}
      >
        <StyledTitle>Updating the oracle</StyledTitle>
      </IntersectionBlock>
      <UpdatingOracle />

      <IntersectionBlock
        onIntersecting={() => setCurrentSection(5)}
        intersectingRef={refs[5]}
        observerConfig={observerConfig}
      >
        <StyledTitle>Privileged functions</StyledTitle>
      </IntersectionBlock>
      <PrivelegedFunctions />

      <IntersectionBlock
        onIntersecting={() => setCurrentSection(6)}
        intersectingRef={refs[6]}
        observerConfig={observerConfig}
      >
        <StyledTitle>Participant set synchronization</StyledTitle>
      </IntersectionBlock>
      <ParticipantSynchronization />

      <IntersectionBlock
        onIntersecting={() => setCurrentSection(7)}
        intersectingRef={refs[7]}
        observerConfig={observerConfig}
      >
        <StyledTitle>Mainnet transacting schedule</StyledTitle>
      </IntersectionBlock>
      <MainnetSchedule />

      <IntersectionBlock
        onIntersecting={() => setCurrentSection(8)}
        intersectingRef={refs[8]}
        observerConfig={observerConfig}
      >
        <StyledTitle>Undisputed objectives</StyledTitle>
      </IntersectionBlock>
      <ol>
        <StyledListItem>
          Implementation secure enough to provide feeds to $1B+ TVL projects.
        </StyledListItem>
        <StyledListItem>Gas-efficient.</StyledListItem>
        <StyledListItem>Each asset must have an update timestamp.</StyledListItem>
      </ol>

      <IntersectionBlock
        onIntersecting={() => setCurrentSection(9)}
        intersectingRef={refs[9]}
        observerConfig={observerConfig}
      >
        <StyledTitle>Connectors</StyledTitle>
      </IntersectionBlock>
      <Connectors />

      <IntersectionBlock
        onIntersecting={() => setCurrentSection(10)}
        intersectingRef={refs[10]}
        observerConfig={observerConfig}
      >
        <StyledTitle>Roadmap</StyledTitle>
      </IntersectionBlock>
      <Roadmap />

      <IntersectionBlock
        onIntersecting={() => setCurrentSection(11)}
        intersectingRef={refs[11]}
        observerConfig={observerConfig}
      >
        <StyledTitle>More data feeds</StyledTitle>
      </IntersectionBlock>
      <ul>
        <StyledListItem>Currencies</StyledListItem>
        <StyledListItem>Commodities</StyledListItem>
        <StyledListItem>Bonds</StyledListItem>
      </ul>
    </>
  );
};
