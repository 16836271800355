import {FC, useEffect, useState} from 'react';
import {ReactComponent as BurgerIcon} from 'src/assets/icons/blockscout-burger.svg';
import styled from 'styled-components';
import {NetworkSelector} from 'src/views/blockscout/components/NetworkSelector';
import {NetworkName} from 'src/views/blockscout/styled';
import {ChainName} from 'src/types/MetadataApi';

const BurgerContainer = styled.div`
  display: contents;
  @media screen and (min-width: 992px) {
    display: none;
  }
`;

const StyledMenu = styled.div`
  width: 100%;
`;

export const BurgerMenu: FC<{chainName?: string; chainId?: ChainName}> = ({chainName, chainId}) => {
  const [showMenu, setShowMenu] = useState(false);

  function onCloseMenu() {
    setShowMenu(false);
  }

  function dropdownHandler(e: React.MouseEvent) {
    e.stopPropagation();
    setShowMenu(!showMenu);
  }

  useEffect(() => {
    if (!showMenu) return;

    document.addEventListener('click', onCloseMenu);

    return () => document.removeEventListener('click', onCloseMenu);
  }, [showMenu]);

  return (
    <BurgerContainer>
      <BurgerIcon onClick={dropdownHandler} style={{width: '30px', cursor: 'pointer'}} />
      {showMenu && (
        <StyledMenu>
          <NetworkSelector currentChain={chainId} />
          <NetworkName>{chainName} chain</NetworkName>
        </StyledMenu>
      )}
    </BurgerContainer>
  );
};
