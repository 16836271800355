export const COLLATERALS: {
  [address: string]: {
    symbol: string;
  };
} = {
  '0x5B5CFE992AdAC0C9D48E05854B2d91C73a003858': {
    symbol: 'husd3CRV',
  },
  '0xdF574c24545E5FfEcb9a659c229253D4111d87e1': {
    symbol: 'HUSD',
  },
  '0x5f18C75AbDAe578b483E5F43f12a39cF75b973a9': {
    symbol: 'yUSDC',
  },
  '0x19D3364A399d251E894aC732651be8B0E4e85001': {
    symbol: 'yDAI',
  },
  '0xEcd5e75AFb02eFa118AF914515D6521aaBd189F1': {
    symbol: 'TUSD3CRV-f',
  },
  '0x674C6Ad92Fd080e4004b2312b45f796a192D27a0': {
    symbol: 'USDN',
  },
  '0x70e36f6BF80a52b3B46b3aF8e106CC0ed743E8e4': {
    symbol: 'cCOMP',
  },
  '0x94e131324b6054c0D789b190b2dAC504e4361b53': {
    symbol: 'ust3CRV',
  },
  '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643': {
    symbol: 'cDAI',
  },
  '0xFAce851a4921ce59e912d19329929CE6da6EB0c7': {
    symbol: 'cLINK',
  },
  '0x35A18000230DA775CAc24873d00Ff85BccdeD550': {
    symbol: 'cUNI',
  },
  '0x4807862AA8b2bF68830e4C8dc86D0e9A998e085a': {
    symbol: 'BUSD3CRV-f',
  },
  '0xd632f22692FaC7611d2AA1C0D552930D43CAEd3B': {
    symbol: 'FRAX3CRV-f',
  },
  '0xccF4429DB6322D5C611ee964527D42E5d685DD6a': {
    symbol: 'cWBTC',
  },
  '0x4f3E8F405CF5aFC05D68142F3783bDfE13811522': {
    symbol: 'usdn3CRV',
  },
  '0xa47c8bf37f92aBed4A126BDA807A7b7498661acD': {
    symbol: 'UST',
  },
  '0x4Fabb145d64652a948d72533023f6E7A623C7C53': {
    symbol: 'BUSD',
  },
  '0x92e187a03b6cd19cb6af293ba17f2745fd2357d5': {
    symbol: 'DUCK',
  },
  '0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9': {
    symbol: 'cUSDT',
  },
  '0x8290333cef9e6d528dd5618fb97a76f268f3edd4': {
    symbol: 'ANKR',
  },
  '0x0abdace70d3790235af448c88547603b945604ea': {
    symbol: 'DNT',
  },
  '0x4fe83213d56308330ec302a8bd641f1d0113a4cc': {
    symbol: 'NU',
  },
  '0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7': {
    symbol: 'AKRO',
  },
  '0x956f47f50a910163d8bf957cf5846d573e7f87ca': {
    symbol: 'FEI',
  },
  '0x4bfb2fa13097e5312b19585042fdbf3562dc8676': {
    symbol: '3CRV-Gauge-Unit',
  },
  '0x5a98fcbea516cf06857215779fd812ca3bef1b32': {
    symbol: 'LDO',
  },
  '0xa1faa113cbe53436df28ff0aee54275c13b40975': {
    symbol: 'ALPHA',
  },
  '0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b': {
    symbol: 'CVX',
  },
  '0x57b946008913b82e4df85f501cbaed910e58d26c': {
    symbol: 'POND',
  },
  '0x0391d2021f89dc339f60fff84546ea23e337750f': {
    symbol: 'BOND',
  },
  '0x6c3f90f043a72fa612cbac8115ee7e52bde6e490': {
    symbol: '3Crv',
  },
  '0xa283aa7cfbb27ef0cfbcb2493dd9f4330e0fd304': {
    symbol: 'MM',
  },
  '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2': {
    symbol: 'ETH',
  },
  '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599': {
    symbol: 'WBTC',
  },
  '0xe41d2489571d322189246dafa5ebde1f4699f498': {
    symbol: 'ZRX',
  },
  '0x0ae055097c6d159879521c384f1d2123d1f195e6': {
    symbol: 'STAKE',
  },
  '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e': {
    symbol: 'YFI',
  },
  '0xd0660cd418a64a1d44e9214ad8e459324d8157f1': {
    symbol: 'WOOFY',
  },
  '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2': {
    symbol: 'MKR',
  },
  '0xd533a949740bb3306d119cc777fa900ba034cd52': {
    symbol: 'CRV',
  },
  '0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44': {
    symbol: 'KP3R',
  },
  '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984': {
    symbol: 'UNI',
  },
  '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9': {
    symbol: 'AAVE',
  },
  '0xc00e94cb662c3520282e6f5717214004a7f26888': {
    symbol: 'COMP',
  },
  '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f': {
    symbol: 'SNX',
  },
  '0x514910771af9ca656af840dff83e8264ecf986ca': {
    symbol: 'LINK',
  },
  '0x04fa0d235c4abf4bcf4787af4cf447de572ef828': {
    symbol: 'UMA',
  },
  '0xeb4c2781e4eba804ce9a9803c67d0893436bb27d': {
    symbol: 'renBTC',
  },
  '0x584bc13c7d411c00c01a62e8019472de68768430': {
    symbol: 'HEGIC',
  },
  '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48': {
    symbol: 'USDC',
  },
  '0xdac17f958d2ee523a2206206994597c13d831ec7': {
    symbol: 'USDT',
  },
  '0x6b175474e89094c44da98b954eedeac495271d0f': {
    symbol: 'DAI',
  },
  '0x57ab1ec28d129707052df4df418d58a2d46d5f51': {
    symbol: 'sUSD',
  },
  '0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b': {
    symbol: 'DPI',
  },
  '0xba11d00c5f74255f56a5e366f4f77f5a186d7f55': {
    symbol: 'BAND',
  },
  '0x408e41876cccdc0f92210600ef50372656052a38': {
    symbol: 'REN',
  },
  '0x3472a5a71965499acd81997a54bba8d852c6e53d': {
    symbol: 'BADGER',
  },
  '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2': {
    symbol: 'SUSHI',
  },
  '0xc944e90c64b2c07662a292be6244bdf05cda44a7': {
    symbol: 'GRT',
  },
  '0xa3bed4e1c75d00fa6f4e5e6922db7261b5e9acd2': {
    symbol: 'MTA',
  },
  '0x429881672b9ae42b8eba0e26cd9c73711b891ca5': {
    symbol: 'PICKLE',
  },
  '0xbc396689893d065f41bc2c6ecbee5e0085233447': {
    symbol: 'PERP',
  },
  '0x967da4048cd07ab37855c090aaf366e4ce1b9f48': {
    symbol: 'OCEAN',
  },
  '0xba100000625a3754423978a60c9317c58a424e3d': {
    symbol: 'BAL',
  },
  '0x2ba592f78db6436527729929aaf6c908497cb200': {
    symbol: 'CREAM',
  },
  '0x5bc25f649fc4e26069ddf4cf4010f9f706c23831': {
    symbol: 'DUSD',
  },
  '0xe2f2a5c287993345a840db3b0845fbc70f5935a5': {
    symbol: 'mUSD',
  },
  '0xd26114cd6ee289accf82350c8d8487fedb8a0c07': {
    symbol: 'OMG',
  },
  '0x4e15361fd6b4bb609fa63c81a2be19d873717870': {
    symbol: 'FTM',
  },
  '0x4688a8b1f292fdab17e9a90c8bc379dc1dbd8713': {
    symbol: 'COVER',
  },
  '0x0aacfbec6a24756c20d41914f2caba817c0d8521': {
    symbol: 'YAM',
  },
  '0xc5bddf9843308380375a611c18b50fb9341f502a': {
    symbol: 'yveCRV-DAO',
  },
  '0x8798249c2e607446efb7ad49ec89dd1865ff4272': {
    symbol: 'XSUSHI',
  },
  '0xf99d58e463a2e07e5692127302c20a191861b4d6': {
    symbol: 'ANY',
  },
  '0x1337def16f9b486faed0293eb623dc8395dfe46a': {
    symbol: 'ARMOR',
  },
  '0x3c9d6c1c73b31c837832c72e04d3152f051fc1a9': {
    symbol: 'BOR',
  },
  '0xb753428af26e81097e7fd17f40c88aaa3e04902c': {
    symbol: 'SFI',
  },
  '0x23b608675a2b2fb1890d3abbd85c5775c51691d5': {
    symbol: 'SOCKS',
  },
  '0xd291e7a03283640fdc51b121ac401383a46cc623': {
    symbol: 'RGT',
  },
  '0x1c5db575e2ff833e46a2e9864c22f4b22e0b37c2': {
    symbol: 'renZEC',
  },
  '0xd5147bc8e386d91cc5dbe72099dac6c9b99276f5': {
    symbol: 'renFIL',
  },
  '0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b': {
    symbol: 'CRO',
  },
  '0x1776e1f26f98b1a5df9cd347953a26dd3cb46671': {
    symbol: 'NMR',
  },
  '0x56d811088235f11c8920698a204a5010a788f4b3': {
    symbol: 'BZRX',
  },
  '0x3832d2f059e55934220881f831be501d180671a7': {
    symbol: 'renDOGE',
  },
  '0xdbdb4d16eda451d0503b854cf79d55697f90c8df': {
    symbol: 'ALCX',
  },
  '0x8888801af4d980682e47f1a9036e589479e835c5': {
    symbol: 'MPH',
  },
  '0x9461173740d27311b176476fa27e94c681b1ea6b': {
    symbol: 'UNI-V2 yvBOOST-WETH',
  },
  '0x86fEf14C27C78dEAEb4349FD959CAA11fc5B5D75': {
    symbol: 'UNI-V2 RARI-ETH',
  },

  '0xc5be99A02C6857f9Eac67BbCE58DF5572498F40c': {
    symbol: 'UNI-V2 AMPL-ETH',
  },
  '0x94b0a3d511b6ecdb17ebf877278ab030acb0a878': {
    symbol: 'UNI-V2 FEI-WETH',
  },
  '0xe6c804ff4ec692e6808c0d032cdbc03772fc4d42': {
    symbol: 'UNI-V2 renZEC-ETH',
  },
  '0x4423be2173401e96065953a3e962ba7b8fdba68a': {
    symbol: 'UNI-V2 renFIL-ETH',
  },
  '0xc3601f3e1c26d1a47571c559348e4156786d1fec': {
    symbol: 'UNI-V2 DUCK-ETH',
  },
  '0xbb2b8038a1640196fbe3e38816f3e67cba72d940': {
    symbol: 'UNI-V2 WBTC-ETH',
  },
  '0x87febfb3ac5791034fd5ef1a615e9d9627c2665d': {
    symbol: 'UNI-V2 KP3R-ETH',
  },
  '0x3da1313ae46132a397d90d95b1424a9a7e3e0fce': {
    symbol: 'UNI-V2 CRV-ETH',
  },
  '0x3b3d4eefdc603b232907a7f3d0ed1eea5c62b5f7': {
    symbol: 'UNI-V2 STAKE-ETH',
  },
  '0x2fdbadf3c4d5a8666bc06645b8358ab803996e28': {
    symbol: 'UNI-V2 YFI-ETH',
  },
  '0xc2adda861f89bbb333c90c492cb837741916a225': {
    symbol: 'UNI-V2 MKR-ETH',
  },
  '0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852': {
    symbol: 'UNI-V2 USDT-ETH',
  },
  '0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc': {
    symbol: 'UNI-V2 USDC-ETH',
  },
  '0xa478c2975ab1ea89e8196811f51a7b7ade33eb11': {
    symbol: 'UNI-V2 DAI-ETH',
  },
  '0xd3d2e2692501a5c9ca623199d38826e513033a17': {
    symbol: 'UNI-V2 UNI-ETH',
  },
  '0xa2107fa5b38d9bbd2c461d6edf11b11a50f6b974': {
    symbol: 'UNI-V2 LINK-ETH',
  },
  '0xdfc14d2af169b0d36c4eff567ada9b2e0cae044f': {
    symbol: 'UNI-V2 AAVE-ETH',
  },
  '0x81fbef4704776cc5bba0a5df3a90056d2c6900b3': {
    symbol: 'UNI-V2 renBTC-ETH',
  },
  '0x43ae24960e5534731fc831386c07755a2dc33d47': {
    symbol: 'UNI-V2 SNX-ETH',
  },
  '0xcffdded873554f362ac02f8fb1f02e5ada10516f': {
    symbol: 'UNI-V2 COMP-ETH',
  },
  '0x88d97d199b9ed37c29d846d00d443de980832a22': {
    symbol: 'UNI-V2 UMA-ETH',
  },
  '0x1273ad5d8f3596a7a39efdb5a4b8f82e8f003fc3': {
    symbol: 'UNI-V2 HEGIC-ETH',
  },
  '0xF1F85b2C54a2bD284B1cf4141D64fD171Bd85539': {
    symbol: 'SLP sUSD-ETH',
  },
  '0x26aAd2da94C59524ac0D93F6D6Cbf9071d7086f2': {
    symbol: 'SLP 1INCH-ETH',
  },
  '0xb46736888247c68c995b156ca86426ff32e27cc9': {
    symbol: 'SLP renDOGE-ETH',
  },
  '0x10b47177e92ef9d5c6059055d92ddf6290848991': {
    symbol: 'SLP yveCRV-DAO-ETH',
  },
  '0x795065dcc9f64b5614c407a6efdc400da6221fb0': {
    symbol: 'SLP SUSHI-ETH',
  },
  '0x001b6450083e531a5a7bf310bd2c1af4247e23d4': {
    symbol: 'SLP UMA-ETH',
  },
  '0x06da0fd433c1a5d7a4faa01111c044910a184553': {
    symbol: 'SLP USDT-ETH',
  },
  '0x088ee5007c98a9677165d78dd2109ae4a3d04d0c': {
    symbol: 'SLP YFI-ETH',
  },
  '0x31503dcb60119a812fee820bb7042752019f2355': {
    symbol: 'SLP COMP-ETH',
  },
  '0x397ff1542f962076d0bfe58ea045ffa2d347aca0': {
    symbol: 'SLP USDC-ETH',
  },
  '0x58dc5a51fe44589beb22e8ce67720b5bc5378009': {
    symbol: 'SLP CRV-ETH',
  },
  '0x611cde65dea90918c0078ac0400a72b0d25b9bb1': {
    symbol: 'SLP REN-ETH',
  },
  '0xa1d7b2d891e3a1f9ef4bbc5be20630c2feb1c470': {
    symbol: 'SLP SNX-ETH',
  },
  '0xa75f7c2f025f470355515482bde9efa8153536a8': {
    symbol: 'SLP BAND-ETH',
  },
  '0xc3d03e4f041fd4cd388c549ee2a29a9e5075882f': {
    symbol: 'SLP DAI-ETH',
  },
  '0xc40d16476380e4037e6b1a2594caf6a6cc8da967': {
    symbol: 'SLP LINK-ETH',
  },
  '0xceff51756c56ceffca006cd410b03ffc46dd3a58': {
    symbol: 'SLP WBTC-ETH',
  },
  '0xd75ea151a61d06868e31f8988d28dfe5e9df57b4': {
    symbol: 'SLP AAVE-ETH',
  },
  '0xdafd66636e2561b0284edde37e42d192f2844d40': {
    symbol: 'SLP UNI-ETH',
  },
  '0xa258C4606Ca8206D8aA700cE2143D7db854D168c': {
    symbol: 'yvWETH',
  },
  '0x671a912C10bba0CFA74Cfc2d6Fba9BA1ed9530B2': {
    symbol: 'yvLINK',
  },
  '0xB8C3B7A2A618C552C23B1E4701109a9E756Bab67': {
    symbol: 'yv1INCH',
  },
  '0x3B96d491f067912D18563d56858Ba7d6EC67a6fa': {
    symbol: 'yvCurve-USDN',
  },
  '0x84E13785B5a27879921D6F685f041421C7F482dA': {
    symbol: 'yvCurve-3pool',
  },
  '0xF29AE508698bDeF169B89834F76704C3B205aedf': {
    symbol: 'yvSNX',
  },
  '0x9d409a0a012cfba9b15f6d4b36ac57a46966ab9a': {
    symbol: 'yvBOOST',
  },
  '0xf8768814b88281DE4F532a3beEfA5b85B69b9324': {
    symbol: 'yvCurve-TUSD',
  },
  '0xB4AdA607B9d6b2c9Ee07A275e9616B84AC560139': {
    symbol: 'yvCurve-FRAX',
  },
  '0xFBEB78a723b8087fD2ea7Ef1afEc93d35E8Bed42': {
    symbol: 'yvUNI',
  },
  '0xE14d13d8B3b85aF791b2AADD661cDBd5E6097Db1': {
    symbol: 'yvYFI',
  },
  '0xa5cA62D95D24A4a350983D5B8ac4EB8638887396': {
    symbol: 'yvsUSD',
  },
  '0xA696a63cc78DfFa1a63E9E50587C197387FF6C7E': {
    symbol: 'yvWBTC',
  },
  '0x6Ede7F19df5df6EF23bD5B9CeDb651580Bdf56Ca': {
    symbol: 'yvCurve-BUSD',
  },
  '0x054AF22E1519b020516D72D749221c24756385C9': {
    symbol: 'yvCurve-HUSD',
  },
  '0x7Da96a3891Add058AdA2E826306D812C638D87a7': {
    symbol: 'yvUSDT',
  },
  '0x1C6a9783F812b3Af3aBbf7de64c3cD7CC7D1af44': {
    symbol: 'yvCurve-UST',
  },
};

export const STABLECOINS = [
  '0x6b175474e89094c44da98b954eedeac495271d0f',
  '0x57ab1ec28d129707052df4df418d58a2d46d5f51',
  '0xdac17f958d2ee523a2206206994597c13d831ec7',
  '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  '0x5bc25f649fc4e26069ddf4cf4010f9f706c23831',
  '0xe2f2a5c287993345a840db3b0845fbc70f5935a5',
  '0xdb25f211ab05b1c97d595516f45794528a807ad8',
  '0x0000000000085d4780b73119b644ae5ecd22b376',
  '0x4fabb145d64652a948d72533023f6e7a623c7c53',
  '0x674c6ad92fd080e4004b2312b45f796a192d27a0',
  '0xdf574c24545e5ffecb9a659c229253d4111d87e1',
  '0x853d955acef822db058eb8505911ed77f175b99e',
  '0x956f47f50a910163d8bf957cf5846d573e7f87ca',
  '0x1456688345527bE1f37E9e627DA0837D6f08C925',
].map((el) => el.toLowerCase());

export const UNILP = [
  '0x9461173740d27311b176476fa27e94c681b1ea6b',
  '0x86fEf14C27C78dEAEb4349FD959CAA11fc5B5D75',
  '0xc5be99A02C6857f9Eac67BbCE58DF5572498F40c',
  '0x94b0a3d511b6ecdb17ebf877278ab030acb0a878',
  '0xe6c804ff4ec692e6808c0d032cdbc03772fc4d42',
  '0x4423be2173401e96065953a3e962ba7b8fdba68a',
  '0xc3601f3e1c26d1a47571c559348e4156786d1fec',
  '0xbb2b8038a1640196fbe3e38816f3e67cba72d940',
  '0x87febfb3ac5791034fd5ef1a615e9d9627c2665d',
  '0x3da1313ae46132a397d90d95b1424a9a7e3e0fce',
  '0x3b3d4eefdc603b232907a7f3d0ed1eea5c62b5f7',
  '0x2fdbadf3c4d5a8666bc06645b8358ab803996e28',
  '0xc2adda861f89bbb333c90c492cb837741916a225',
  '0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852',
  '0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc',
  '0xa478c2975ab1ea89e8196811f51a7b7ade33eb11',
  '0xd3d2e2692501a5c9ca623199d38826e513033a17',
  '0xa2107fa5b38d9bbd2c461d6edf11b11a50f6b974',
  '0xdfc14d2af169b0d36c4eff567ada9b2e0cae044f',
  '0x81fbef4704776cc5bba0a5df3a90056d2c6900b3',
  '0x43ae24960e5534731fc831386c07755a2dc33d47',
  '0xcffdded873554f362ac02f8fb1f02e5ada10516f',
  '0x88d97d199b9ed37c29d846d00d443de980832a22',
  '0x1273ad5d8f3596a7a39efdb5a4b8f82e8f003fc3',
].map((el) => el.toLowerCase());

export const SLP = [
  '0xF1F85b2C54a2bD284B1cf4141D64fD171Bd85539',
  '0x26aAd2da94C59524ac0D93F6D6Cbf9071d7086f2',
  '0xb46736888247c68c995b156ca86426ff32e27cc9',
  '0x10b47177e92ef9d5c6059055d92ddf6290848991',
  '0x795065dcc9f64b5614c407a6efdc400da6221fb0',
  '0x001b6450083e531a5a7bf310bd2c1af4247e23d4',
  '0x06da0fd433c1a5d7a4faa01111c044910a184553',
  '0x088ee5007c98a9677165d78dd2109ae4a3d04d0c',
  '0x31503dcb60119a812fee820bb7042752019f2355',
  '0x397ff1542f962076d0bfe58ea045ffa2d347aca0',
  '0x58dc5a51fe44589beb22e8ce67720b5bc5378009',
  '0x611cde65dea90918c0078ac0400a72b0d25b9bb1',
  '0xa1d7b2d891e3a1f9ef4bbc5be20630c2feb1c470',
  '0xa75f7c2f025f470355515482bde9efa8153536a8',
  '0xc3d03e4f041fd4cd388c549ee2a29a9e5075882f',
  '0xc40d16476380e4037e6b1a2594caf6a6cc8da967',
  '0xceff51756c56ceffca006cd410b03ffc46dd3a58',
  '0xd75ea151a61d06868e31f8988d28dfe5e9df57b4',
  '0xdafd66636e2561b0284edde37e42d192f2844d40',
].map((el) => el.toLowerCase());

export const COMPOUND = [
  '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643',
  '0xFAce851a4921ce59e912d19329929CE6da6EB0c7',
  '0x35A18000230DA775CAc24873d00Ff85BccdeD550',
  '0x70e36f6BF80a52b3B46b3aF8e106CC0ed743E8e4',
  '0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9',
  '0xccF4429DB6322D5C611ee964527D42E5d685DD6a',
].map((el) => el.toLowerCase());

export const YEARN = [
  '0x94e131324b6054c0D789b190b2dAC504e4361b53',
  '0x5B5CFE992AdAC0C9D48E05854B2d91C73a003858',
  '0xB8C3B7A2A618C552C23B1E4701109a9E756Bab67',
  '0xa258C4606Ca8206D8aA700cE2143D7db854D168c',
  '0x671a912C10bba0CFA74Cfc2d6Fba9BA1ed9530B2',
  '0x3B96d491f067912D18563d56858Ba7d6EC67a6fa',
  '0x84E13785B5a27879921D6F685f041421C7F482dA',
  '0xF29AE508698bDeF169B89834F76704C3B205aedf',
  '0x9d409a0a012cfba9b15f6d4b36ac57a46966ab9a',
  '0xf8768814b88281DE4F532a3beEfA5b85B69b9324',
  '0xB4AdA607B9d6b2c9Ee07A275e9616B84AC560139',
  '0xFBEB78a723b8087fD2ea7Ef1afEc93d35E8Bed42',
  '0xE14d13d8B3b85aF791b2AADD661cDBd5E6097Db1',
  '0xa5cA62D95D24A4a350983D5B8ac4EB8638887396',
  '0xA696a63cc78DfFa1a63E9E50587C197387FF6C7E',
  '0x6Ede7F19df5df6EF23bD5B9CeDb651580Bdf56Ca',
  '0x054AF22E1519b020516D72D749221c24756385C9',
  '0x7Da96a3891Add058AdA2E826306D812C638D87a7',
  '0x1C6a9783F812b3Af3aBbf7de64c3cD7CC7D1af44',
  '0x19d3364a399d251e894ac732651be8b0e4e85001',
  '0x5f18c75abdae578b483e5f43f12a39cf75b973a9',
  '0xc5bddf9843308380375a611c18b50fb9341f502a',
].map((el) => el.toLowerCase());

export const TREE_CRV = [
  '0x4f3e8f405cf5afc05d68142f3783bdfe13811522',
  '0x5b5cfe992adac0c9d48e05854b2d91c73a003858',
  '0x94e131324b6054c0d789b190b2dac504e4361b53',
].map((el) => el.toLowerCase());

export const SYS_TOKEN = '0x0000000000000000000000000000000000000001';
export const SYS_BTC = '0x0000000000000000000000000000000000000002';

export const PARTICIPANTS = {
  '0x07539B20ACC6Eaae3649d56Fcb14F56737F43022': 'Gryphon',
  '0x2056fF92842CE365A3f7b3D85E350bF237dd60EF': 'Niffler',
  '0x2F796E41372DfF80CcA1592553Db612b3662c262': 'Glider',
  '0x346c52928932624CA062220b36D7AC3fAC51B9BE': 'Wamblee',
  '0x4155235b2327a593D2E1dC487FF81a89768Ab46F': 'Flimflam',
  '0x4528485BA12dc2532256ee74A46B8D899F9CF36a': 'Noggin',
  '0x626e0D83a88eC77753BcA06d0399F49Cf58EC0d8': 'Glimmer',
  '0x7727d2D3f888525DCed2da124C9caDC294361A5D': 'Spriggan',
  '0x80f5dDBd23f00BeD698056d7e46F32B266029f61': 'Splinter',
  '0xC67e48FAe72636e8262E0a2BCEE06CfaB4aa4120': 'Jibber',
} as Record<string, string>;
