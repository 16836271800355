import {FC} from 'react';
import {CHAINS} from 'src/constants/chains';
import {ChainName} from 'src/types/MetadataApi';
import {ReactComponent as Logo} from 'src/assets/logo.svg';
import styled from 'styled-components';
import {blockscoutColor} from 'src/views/blockscout/colors';
import {NetworkSelector} from 'src/views/blockscout/components/NetworkSelector';
import {BurgerMenu} from 'src/views/blockscout/components/BurgerMenu';
import {NetworkName} from 'src/views/blockscout/styled';

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 48px;
  padding: 8px 16px;
  background: ${blockscoutColor.white};
  box-shadow: 0 0 30px 0 rgb(21 53 80 / 12%);

  svg {
    max-width: 170px;
  }
`;

const Navbar = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 991px) {
    #network-selector,
    #network-name {
      display: none;
    }
  }
`;

export const BlockscoutHeader: FC<{networkId?: ChainName}> = ({networkId}) => {
  const chainName = CHAINS.find((chain) => chain.id === networkId)?.name;
  const multidataExplorer = CHAINS.find((chain) => chain.id === 'bobhub')?.explorer;

  return (
    <HeaderContainer>
      <a href={multidataExplorer}>
        <Logo style={{color: '#171717'}} />
      </a>
      <Navbar>
        <NetworkSelector id="network-selector" currentChain={networkId} />
        <NetworkName id="network-name">{chainName} chain</NetworkName>
      </Navbar>
      <BurgerMenu chainName={chainName} chainId={networkId} />
    </HeaderContainer>
  );
};
