import {createGlobalStyle} from 'styled-components';
import {breakpoint, color, fontFamily} from '../components/UI/theme';

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Circe Regular';
    src: url('/fonts/Circe/Circe_Regular.woff2') format('woff2'),
         url('/fonts/Circe/Circe_Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Circe Regular';
    src: url('/fonts/Circe/Circe_Bold.woff2') format('woff2'),
         url('/fonts/Circe/Circe_Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Nunito';
    src: url('/fonts/NunitoRegular/Nunito-Regular.woff2') format('woff2'),
         url('/fonts/NunitoRegular/Nunito-Regular.woff') format('woff'),
         url('/fonts/NunitoRegular/Nunito-Regular.otf') format('otf');
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'General Sans';
    src: url(fonts/GeneralSans/GeneralSans-Bold.woff2) format('woff2'),
      url(fonts/GeneralSans/GeneralSans-Bold.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  html {
    font-family: ${fontFamily.inter};
    font-size: calc(100vw / 60);
    min-height: 100vh;
    background-color: ${color.white};
    @media (min-width: ${breakpoint.tablet}) {
      font-size: calc(100vw / 100);
    }
    @media (min-width: ${breakpoint.desktop}) {
      font-size: calc(100vw / 179);
    }
    @media (min-width: ${breakpoint.hd}) {
      font-size: calc(100vw / 179);
    }
    @media (min-width: ${breakpoint.fullHd}) {
      font-size: 10px;
    }
  }

  * {
    box-sizing: border-box;
  }
`;
