export const knowledgeCenter = {
  aboutUs: {
    title: 'Chunk Network Whitepaper',
    text: `Chunk oracle is optimized for storing and efficiently updating a significant amount of on-chain price quotes. The logarithmic delta encoding is applied to price updates. The gas
    cost of one update is 73300 gas, the price update precision is 0.2%. The solution is
    designed with decentralization in mind. The deterministic quoting approach will allow
    techniques such as threshold signatures to be applied on top of it. This way the price feeds
    may be constructed by multiple parties off-chain and delivered with a single verifiable
    signature on-chain.This results in significant gas savings compared to other oracle
    protocols, e.g. Chainlink. The importance of the off-chain logic can’t be underestimated as
    will be explained below.`,
    assets: [
      {
        name: 'Stablecoins',
        text: 'USDC, USDT, TUSD, BUSD, sUSD, DUSD, USDN, DAI, FEI, UST, HUSD, mUSD, USDP',
      },
      {
        name: 'Tokens',
        text: 'WBTC, WETH, ORN, BOND, UMA, YAM, DNT, STAKE, BAT, MANA, YFI, 1INCH, ARMOR, DPI, NMR, VSP, KP3R, UNI, REPv2, SOCKS, CREAM, BADGER, BOR, REN, PICKLE, OXT, COVER, WOO, TRU, FTM, CVX, NU, LINK, BZRX, POND, HEGIC, LDO, GNO, SUSHI, MATIC, AAVE, OGN, ANKR, FRAX, KEEP, xSUSHI, MPH, AKRO, SXP, DUCK, SHIB, OCEAN, MKR, CRO, ADX, BAND, LRC, SNX, WOOFY, RGT, renFIL, CRV, ZRX, renBTC, ENJ, RARI, ANT, ALPHA, MM, MTA, CEL, SFI, PERP, BAL, COMP, GRT, OMG, ALCX, KNC, INJ, ANY, SYS',
      },
      {
        name: 'Compound tokens (cTokens)',
        text: 'cWBTC, cUNI, cCOMP, cLINK, cUSDT, cDAI',
      },
      {
        name: 'Yearn Vaults',
        text: 'yvCurve-HUSD, yvCurve-UST, yvCurve-USDN, husd3CRV, yvLINK, yvCurve-BUSD, yvUSDT, yvCurve-3pool, ust3CRV, yvBOOST, yvWBTC, yvCurve-FRAX, yv1INCH, yvYFI, TUSD3CRV-f, yvSNX, yvUNI, yvWETH, yvsUSD, yvCurve-TUSD',
      },
      {
        name: 'Sushiswap LP tokens',
        text: 'SLP: WETH-USDT, USDC-WETH, WBTC-WETH, UMA-WETH, YFI-WETH, WETH-yveCRV, 1INCH-WETH, COMP-WETH, WETH-CRV, REN-WETH, SUSHI-WETH, SNX-WETH, BAND-WETH, DAI-WETH, LINK-WETH, AAVE-WETH, UNI-WETH, sUSD-WETH, renDOGE-WETH',
      },
      {
        name: 'Uniswap V2 LP tokens',
        text: 'WETH-USDT, WETH-renBTC, USDC-WETH, WBTC-WETH, renZEC-WETH, WETH-AMPL, HEGIC-WETH, YFI-WETH, STAKE-WETH, WETH-CRV, SNX-WETH, WETH-renFIL, WETH-RARI, KP3R-WETH, UMA-WETH, yvBOOST-WETH, FEI-WETH, DAI-WETH, MKR-WETH, COMP-WETH, AAVE-WETH, LINK-WETH, DUCK-WETH, UNI-WETH',
      },
      {
        name: 'Pool LP tokens (3crv)',
        text: 'usdn3CRV, husd3CRV, ust3CRV',
      },
    ],
    sources:
      'Binance, Huobi, Kraken, BitMart, Hotbit, MEXC, OKX, Coinbase, Uniswap V2, Uniswap V3, Sushi, Compound, Curve, Upbit, Gate.io, BigONE, Bitget, Bybit, DigiFinex, Bitfinex, Kukoin, P2PB2B, LBank, WhiteBIT, Gemini, XT.com',
  },
  specification: {
    title: 'Specification',
    text: 'The third option was chosen and further developed. The idea here is to coordinate participants and provide intermediate data storage via a side network (Gnosis Chain, Fantom, etc). The benefits of using a side network:',
    list: [
      'no central point of communication (and hence failure)',
      'participants are DDoS-protected',
      'the network can be easily switched (in the case of complete network failure / DoS)',
      'protocol does not trust the side network',
    ],
  },
  flow: {
    title: 'Flow',
    sections: [
      {
        title: 'Adding assets',
        paragraphs: [
          'Assets have to be added to the contract to allow querying and updating. The process is quite straightforward and utilizes the regular quoting mechanics to get the initial price. The access control is the same as for updating quotes, however, any other viable governance strategy may be implemented. There may be several on-chain oracles with different precision/gas cost trade-offs.',
        ],
      },
      {
        title: 'Quoting',
        paragraphs: [
          'Quoting process is the deterministic interpretation of a set of rules. That allows any party to get the same result regardless of the calculation time or other party-specific conditions. Typically, each rule is a smart combination of data feeds from the most reliable sources of liquidity for the particular asset.',
          'Another key idea behind the quoting approach is the elimination of reliance on any single centralized party, data feed, or asset. E.g., we never rely on the correctness of Binance data and never assume prices of major stablecoins to be close to 1. Finally, all the data feeds are implemented with built-in flash loan resistance to overcome the typical weak spot of oracle solutions.',
        ],
      },
      {
        title: 'Multiparty operation',
        paragraphs: [
          'At the moment multiparty ECDSA is being considered as a solution to the decentralization goal since only the ECDSA verification is relatively cheap on the Ethereum mainnet.',
          'Auxiliary protocol message exchanges may be conducted in a trustless way via cheaper networks like BSC or Gnosis Chain to provide the extra transparency and robustness.',
        ],
      },
      {
        title: 'On-chain querying',
        paragraphs: [
          'Only one function with an intuitive interface should be called to get a price of an asset. The price interpretation depends on the price format of the quoting rules. The oracle protocol itself is format-agnostic and flexible.',
        ],
      },
    ],
  },
  components: {
    title: 'Components',
    contract: {
      title: 'Oracle contract',
      text: 'The contract is quite minimalistic. For each asset, it keeps the price at some moment in the past, and the difference (delta) to calculate the last known price on the fly. It is much cheaper to store a batch of differences than to update the prices one by one. Additionally, the contract exposes several view functions to support the updater library operation (see below).',
    },
    updaterLibrary: {
      title: 'Updater library',
      text: 'Since the contract exposes quite a low-level data model (as well as some other primitives), the approach to updating the quotes can be quite flexible and can be changed on the fly. It will not require the contract redeployment as quote updating is coded off-chain in a form of a library. The idea is simple: if the difference between the base price and the current price can be represented by a small delta, use the delta. Otherwise, set the base price to the current price. Because a delta is valid only for a particular base price, we must ensure that the base price is not updated concurrently. To save storage read calls a simple 64-bit checksum is used. This task is also handled by the library.',
    },
    quotingLibrary: {
      title: 'Quoting library',
      paragraphs: [
        'A set of quoting rules is expressed in a configuration file. A quoting library processes the rules and constructs a tree of objects called price feeds. Here we take a modular approach to decompose a quoting task as a tree of ready-made price feeds, e.g. UniswapV3Feed.',
        'There may be subtleties in handling one or another price source. To keep the quoting approach deterministic, all these subtleties must be specified and handled by the parties in the same way. They are not allowed to expose any non-determinism or randomness.',
        'Currently, there is no formal specification of quoting rules and feeds. However, it can easily be derived from the existing implementation. We are assuming that in the future there will be alternative implementations to facilitate decentralization and reliability.',
      ],
    },
    daemon: {
      title: 'Daemon',
      text: 'There is the last piece to glue things together - the code that pulls data from quoting library and feeds it into the contract using the updater library. This code can be run as a Unix daemon or as a Docker container.',
    },
    compatibilityContract: {
      title: 'Chainlink Compatibility contract',
      text: 'We provide Chainlink-compatible aggregator (IChainlinkAggregatorV2V3Interface) for every quoted asset via special contract which is able to generate numerous aggregators in a gas-efficient way.',
    },
    threatModel: {
      title: 'Threat model',
      code: 'M = 2 * N // 3 + 1',
      list: [
        'At least {{code}} participants are honest, non-compromised and operational.',
        'A mainnet (the network which hosts the oracle contract) is reasonably operational, live, and protected from deep reorganizations.',
        'Mainnet nodes used by honest participants work correctly.',
        'No significant changes of the participant set are performed at once. Only one participant set change may be affected by a mainnet reorganization at the same time.',
      ],
    },
    protocols: {
      title: 'Multiparty protocols',
      intro:
        'Are required to achieve the “implementation secure enough to provide feeds to $1B+ TVL projects” objective.',
      code1: '3340 * N',
      code2: '(3340 + calldata) * N',
      code3: '(up to 7000 * N for 170 assets)',
      list: [
        {
          title: 'Deterministic feeds',
          text: 'Attach to each privileged transaction at least M out of N (v, r, s)-signatures instead of owner’s signature.',
          cons: [
            'only “manual” punishment (expulsion) for dishonest nodes',
            'signees have to compute feeds in a deterministic way, even slightest disagreement ruins liveness.',
            'dishonest nodes may skip the quoting work by copying other’s results',
            'up to {{code1}} extra gas cost',
            'small changes to the main contract',
            'small coordination helper contract for a cheap net',
            'small daemon changes',
          ],
        },
        {
          title: 'Almost deterministic feeds',
          text: 'In the case when there is a disagreement on the feed data, extra stage is taken to sign the feed data where for each asset the price reported by at least M participants is taken, or the current one, if no such price exists.',
          cons: [
            'only “manual” punishment (expulsion) for dishonest nodes',
            'signees are urged to compute feeds in a deterministic way, but slight disagreements are acceptable.',
            'dishonest nodes may skip the quoting work by copying other’s results',
            'up to {{code1}} extra gas cost',
            'extra stage in a case of a disagreement',
            'small changes to the main contract',
            'medium-complexity coordination helper contract for a cheap net',
            'medium-complexity daemon changes',
          ],
        },
        {
          title: 'Non-deterministic feeds, commit-reveal',
          text: 'The first stage: commit of hashes of feed data. The second stage: feed data reveal. After the second stage, a median is computed for each asset in the cheap net’s helper contract. During the third stage, an array of resulting medians is signed by all honest participants.',
          cons: [
            'only “manual” punishment (expulsion) for dishonest nodes',
            'up to {{code1}} extra gas cost',
            'extra 2 stages',
            'small changes to the main contract',
            'medium-complexity coordination helper contract for a cheap net',
            'medium-complexity daemon changes',
          ],
        },
        {
          title: 'Non-deterministic feeds, median-in-the-mainnet',
          text: 'For feed update transactions signees also provide feed data along with signatures. In the main contract a median of the submitted values is computed. Commit-reveal makes no sense here since a byzantine node still can send his data to the main contract.',
          cons: [
            'only “manual” punishment (expulsion) for dishonest nodes',
            'dishonest nodes may skip the quoting work by copying other’s results',
            'up to {{code2}} extra gas cost {{code3}}',
            'small changes to the main contract',
            'small coordination helper contract for a cheap net',
            'medium-complexity daemon changes',
          ],
        },
      ],
      conclusion:
        'The last stage in the options 1-3 (M+ individual signings) may be replaced with a threshold ECDSA signature generation, eliminating the “up to {{code1}} extra gas cost” downside, but adding complexity to the daemon. Additionally, any multi-signature participant change requires new group key generation and updating the owner of the main contract.',
    },
  },
  participants: {
    title: 'Participants',
    paragraphs: [
      'The oracle is managed by a set of participating parties designated by an Ethereum-compatible key pair. There is a quorum requirement (a positive number not greater than the number of the participants) to enact an oracle operation.',
      'There may be at least two approaches to cryptographically secure authorize an operation by such a participants committee:',
      'Regardless of the way chosen, most of the protocol stays the same. Differences are highlighted.',
    ],
    code: '5300 * (quorum - 1)',
    ecrecover: 'ecrecover',
    list: [
      'Transmit a quorum of signatures to the mainnet and verify them on-chain using ecrecover in a loop. Implementation of this approach is named the multisignature case in the document below. It’s a simple solution but requires an extra approx. {{code}} gas (further optimizations are possible).',
      'Create an Ethereum-compatible threshold signature and verify it on-chain using {{ecrecover}}. Signing the mainnet transaction itself is less favorable as changing the fee or the sender (see mainnet transacting schedule) requires re-signing. Implementation of this approach is named the threshold signature case in the document below.',
    ],
  },
  connectors: [
    {
      title: 'DEXs connectors:',
      list: [
        'UniswapV2Feed - for quoting assets at UniswapV2Like swaps: univ2, sushi, shiba, pancake',
        'UniswapV2LPFeed - for quoting liquidity pool tokens at UniswapV2Like swaps: univ2, sushi, shiba, pancake',
        'UniswapV3Feed - for quoting assets at UniswapV3 swap',
        'BearingFeed - for quoting bearing tokens.',
        'CurveLPFeed - for quoting liquidity pool tokens at curve.finance pools',
        'CurveFeed - for quoting assets at curve.finance pools',
        'YvFeed - for quoting yv tokens',
        'CompoundFeed - for quoting compound tokens',
      ],
    },
    {
      title: 'CEXs connectors:',
      list: [
        // 'SimpleStockFeed - quote of stocks',
        'BinanceFeed - for quoting assets at binance',
        'HuobiFeed - huobi',
        'KrakenFeed - kraken',
        'GateioFeed - gateio',
        'CoinbaseSimpleFeed - returns symbol price',
        'Bitmart',
        'Hotbit',
        'MEXC',
        'OKX',
        'Upbit',
        'Xtcom',
        'Kucoin',
        'Lbank',
        'Whitebit',
        'Bitget',
        'BigONE',
        'Bitfinex',
        'Bybit',
        'Digifinex',
        'Gemini',
        'P2PB2B',
      ],
    },
    {
      title: 'Other feeds:',
      list: [
        'MulFeed - multiply price of one feed to another',
        'MedianFeed - find median value from multiple feeds prices, allowed to set count of absent values',
        'InvertFeed - 1 / feed price',
        'AliasFeed - add synonym to feed name',
        'ConstantFeed - for int constants',
        'FallbackFeed - Queries a list of feeds and returns the first successful response',
      ],
    },
  ],
  implementationTabs: [
    'Intro',
    'Components',
    'Participants',
    'Message hashing & signing',
    'Updating the oracle',
    'Privileged functions',
    'Participant set synchronization',
    'Mainnet transacting schedule',
    'Undisputed objectives',
    'Connectors',
    'Roadmap',
    'More data feeds',
  ],
  examplesTabs: ['Solidity', 'JavaScript', 'Python'],
};
