import {breakpoint, color} from 'src/components/UI/theme';
import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1170px;
  margin: 0px auto;
  padding: 2rem 3rem 0;
  min-width: 280px;
  width: 100%;
  a {
    color: ${color.darkBlack};
  }
`;

export const NetworkWrapper = styled.div`
  display: block;
  span {
    display: block;
  }
`;

export const SelectWrapper = styled.div`
  grid-column: 2/3;
  grid-row: 1/3;
  display: flex;
  flex-wrap: wrap;
  align-items: self-start;
  justify-content: flex-end;
  padding-left: 6rem;
  @media screen and (max-width: ${breakpoint.desktop}) {
    display: contents;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    font-size: 2.5rem;
    color: ${color.darkBlack};
    padding-right: 4rem;
    :hover {
      text-decoration: underline;
    }
    :last-of-type {
      padding-right: 0;
    }
  }

  svg {
    width: 3.5rem;
    height: 3.5rem;
  }

  @media screen and (max-width: ${breakpoint.tablet}) {
    > a {
      display: none;
    }
  }
`;
