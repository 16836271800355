import {MainTitle, StyledWrap} from 'src/views/home/components/About/styled';
import {StyledText} from 'src/views/home/styled';

export const About: React.FC = () => (
  <StyledWrap>
    <div style={{maxWidth: '140rem'}}>
      <MainTitle>
        Unlock Your&nbsp;dApps' Full&nbsp;Potential with Chunk's Decentralized Oracle Solution
      </MainTitle>
      <StyledText
        style={{
          marginTop: '4.5rem',
          maxWidth: '140rem',
        }}
      >
        Discover the benefits of Chunk, a unique stand-alone blockchain platform that serves as
        a decentralized oracle. Gain access to a vast array of accurate financial metrics for your
        decentralized applications (dApps) while enjoying seamless integration with Ethereum Virtual
        Machine (EVM) compatible networks. Securely and accurately distribute financial data on the
        blockchain with Chunk's cost-effective solution.
      </StyledText>
    </div>
  </StyledWrap>
);
