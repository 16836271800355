import axios, {AxiosPromise} from 'axios';
import {MetadataApi} from '../types/MetadataApi';

function getMetadata(): AxiosPromise<MetadataApi> {
  return axios.get('https://api.metadata.fit/');
}

function getMultiparty(): AxiosPromise<MetadataApi> {
  return axios.get('https://api.metadata.fit/v2');
}

export const metadata = {
  getMetadata,
  getMultiparty,
};
