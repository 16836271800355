import React, {FC, ReactElement, useEffect, useMemo, useRef, useState} from 'react';
import {color} from 'src/components/UI/theme';
import {Container} from 'src/views/home/styled';
import {AboutUs} from 'src/views/knowledge-center/components/AboutUs';
import {Flow} from 'src/views/knowledge-center/components/Flow';
import {Specification} from 'src/views/knowledge-center/components/Specification';
import {
  Content,
  MainBlock,
  MainBlockTitle,
  Menu,
  MenuContainer,
} from 'src/views/knowledge-center/styled';
import {ReactComponent as SelectIcon} from 'src/assets/icons/ic-select-dropdown.svg';
import {Implementation} from 'src/views/knowledge-center/components/Implementation';
import {Examples} from 'src/views/knowledge-center/components/Examples';
import {knowledgeCenter} from 'src/views/knowledge-center/knowledgeCenter';
import {ChainlinkUsers} from 'src/views/knowledge-center/components/ChainlinkUsers';
import {DefiExamples} from 'src/views/knowledge-center/components/DefiExamples';
import {OracleApi} from 'src/views/knowledge-center/components/OracleApi';
import {SupportedNetworks} from 'src/views/knowledge-center/components/SupportedNetworks';
import {CHAINS} from 'src/constants/chains';
import {ScrollToTop} from 'src/components/UI/ScrollToTop';

export type Tab = {
  id: string;
  name: string;
  content: ReactElement;
  subTabs?: string[];
  refs?: React.RefObject<HTMLDivElement>[];
};

export const KnowledgeCenter: FC = () => {
  const {implementationTabs, examplesTabs} = knowledgeCenter;

  const [currentTab, setCurrentTab] = useState<Tab>();
  const [currentSubTab, setCurrentSubTab] = useState(0);
  const [expanded, setExpanded] = useState(false);

  const menuRef = useRef<HTMLDivElement>(null);

  const chainsWithContracts = useMemo(
    () => CHAINS.filter((chain) => chain.contracts || chain.validators),
    [],
  );

  const implementationRefs = React.useMemo(
    () => implementationTabs.map(() => React.createRef<HTMLDivElement>()),
    [implementationTabs],
  );

  const examplesRefs = React.useMemo(
    () => examplesTabs.map(() => React.createRef<HTMLDivElement>()),
    [examplesTabs],
  );

  const contractsRefs = React.useMemo(
    () => chainsWithContracts.map(() => React.createRef<HTMLDivElement>()),
    [chainsWithContracts],
  );

  const tabs: Tab[] = React.useMemo(
    () => [
      {
        id: 'aboutUs',
        name: 'About us',
        content: <AboutUs />,
      },
      {
        id: 'specification',
        name: 'Specification',
        content: <Specification />,
      },
      {
        id: 'Supported  Networks',
        name: 'Supported  Networks',
        content: (
          <SupportedNetworks
            chains={chainsWithContracts}
            refs={contractsRefs}
            setCurrentSection={setCurrentSubTab}
          />
        ),
        subTabs: chainsWithContracts.map((contract) => contract.name),
        refs: contractsRefs,
      },
      {
        id: 'flow',
        name: 'Flow',
        content: <Flow />,
      },
      {
        id: 'implementation',
        name: 'Implementation',
        content: <Implementation refs={implementationRefs} setCurrentSection={setCurrentSubTab} />,
        subTabs: implementationTabs,
        refs: implementationRefs,
      },
      {
        id: 'examples',
        name: 'Examples on popular languages',
        content: <Examples refs={examplesRefs} setCurrentSection={setCurrentSubTab} />,
        subTabs: examplesTabs,
        refs: examplesRefs,
      },
      {
        id: 'chainlink',
        name: 'For Chainlink users',
        content: <ChainlinkUsers />,
      },
      {
        id: 'apiReference',
        name: 'Oracle Api reference',
        content: <OracleApi />,
      },
      {
        id: 'defiExamples',
        name: 'Examples of usage by DEFI projects',
        content: <DefiExamples />,
      },
    ],
    [
      contractsRefs,
      chainsWithContracts,
      implementationRefs,
      implementationTabs,
      examplesRefs,
      examplesTabs,
    ],
  );

  function onTabClick(tab: Tab) {
    setExpanded(tab === currentTab ? !expanded : true);
    setCurrentTab(tab);
  }

  function onSubTabClick(
    e: React.MouseEvent,
    index: number,
    refs?: React.RefObject<HTMLDivElement>[],
  ) {
    e.stopPropagation();
    setCurrentSubTab(index);
    if (!refs) return;
    refs[index]?.current?.scrollIntoView({
      behavior: 'smooth',
    });
  }

  useEffect(() => {
    window.scrollTo({top: 0});
  }, []);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    menuRef.current?.scrollTo({top: 0, behavior: 'smooth'});
    setCurrentSubTab(0);
  }, [currentTab]);

  useEffect(() => {
    setCurrentTab(tabs[0]);
  }, [tabs]);

  return (
    <Container>
      <MainBlockTitle>Chunk Network Documentation</MainBlockTitle>
      <Content>
        <MenuContainer>
          <Menu ref={menuRef}>
            <ul>
              {tabs.map((tab) => (
                <li
                  onClick={() => onTabClick(tab)}
                  style={{color: currentTab === tab ? color.blackText : color.grayText}}
                  key={tab.id}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'baseline',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>{tab.name}</span>
                    {tab.subTabs && (
                      <SelectIcon
                        style={{color: currentTab === tab ? color.blackText : color.grayText}}
                      />
                    )}
                  </div>
                  {expanded && tab.subTabs && currentTab === tab && (
                    <ul style={{padding: '1rem 0'}}>
                      {tab.subTabs.map((subTab, index) => (
                        <li
                          key={index}
                          onClick={(e) => onSubTabClick(e, index, tab.refs)}
                          style={{
                            color: index === currentSubTab ? color.blackText : color.grayText,
                          }}
                        >
                          {subTab}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </Menu>
        </MenuContainer>
        <MainBlock>{currentTab?.content}</MainBlock>
        <ScrollToTop />
      </Content>
    </Container>
  );
};
