import {FC} from 'react';
import {ReactComponent as SuccessIcon} from 'src/assets/icons/circle-check.svg';
import {ReactComponent as ErrorIcon} from 'src/assets/icons/circle-xmark.svg';
import styled from 'styled-components';
import {blockscoutColor} from 'src/views/blockscout/colors';

const StatusContainer = styled.div`
  display: flex;
  margin-right: 15px;
  min-width: 90px;
`;

const IconWrap = styled.div`
  margin-right: 15px;
  width: 14px;
  height: 14px;
`;

export const StatusPoint: FC<{error?: boolean}> = ({error}) => (
  <StatusContainer>
    <IconWrap style={{fill: error ? blockscoutColor.error : blockscoutColor.success}}>
      {error ? <ErrorIcon /> : <SuccessIcon />}
    </IconWrap>
    <span>{error ? 'Error' : 'Success'}</span>
  </StatusContainer>
);
