import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import {PARTICIPANTS} from 'src/constants/collaterals';
import {useNetwork} from 'src/hooks/useNetwork';
import {Version} from 'src/types/Asset';
import {ChainName} from 'src/types/MetadataApi';
import moment from 'moment';
import {
  Wrap,
  Divider,
  QuorumInfoWrap,
  RightBlock,
  Row,
  StyledTitle,
  Term,
} from 'src/views/blockscout/styled';
import {StatusPoint} from 'src/views/blockscout/components/StatusPoint';
import {blockscoutColor} from 'src/views/blockscout/colors';

export const BlockscoutValidators = () => {
  const {networkId, version} = useParams<{
    networkId: ChainName;
    version: Version;
  }>();
  const {networkAssets, networkExplorer} = useNetwork(networkId, 'tokens', version);
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 767px)'});

  const assets = networkAssets?.tokens;

  const lastUpdated = assets
    ? moment(assets.updated * 1000)
        .utc()
        .format('LLL')
    : '';

  const postMessage = () => {
    window.parent.postMessage({validatorsHeight: `${window.document.body.scrollHeight}px`}, '*');
  };

  useEffect(() => {
    postMessage();
  }, [assets]);

  useEffect(() => {
    window.addEventListener('resize', postMessage);

    return () => window.removeEventListener('resize', postMessage);
  }, []);

  return (
    <Wrap>
      <StyledTitle>Validators</StyledTitle>
      {assets?.multiparty?.participants.map((el) => (
        <Row key={el}>
          <Term>{PARTICIPANTS[el]}</Term>
          <RightBlock>
            <div style={{display: 'flex', alignItems: 'center'}}>
              {assets.multiparty?.signers.includes(el) ? <StatusPoint /> : <StatusPoint error />}
              <a
                href={`${networkExplorer}/address/${el}`}
                target="_blank"
                rel="noreferrer"
                style={{color: blockscoutColor.blackText}}
              >
                {isTabletOrMobile ? `${el.slice(0, 4)}...${el.slice(-4)}` : el}
              </a>
            </div>
          </RightBlock>
        </Row>
      ))}
      <Divider />
      <StyledTitle>
        Quorum {assets?.multiparty?.quorum}/{assets?.multiparty?.participants.length}. Signers:
      </StyledTitle>
      {assets?.multiparty?.signers.map((el) => (
        <Row key={el}>
          <Term>{PARTICIPANTS[el]}</Term>
          <QuorumInfoWrap>
            <div style={{display: 'flex'}}>
              {assets.multiparty?.signers.includes(el) ? <StatusPoint /> : <StatusPoint error />}
              <a
                href={`${networkExplorer}/address/${el}`}
                target="_blank"
                rel="noreferrer"
                style={{color: blockscoutColor.blackText}}
              >
                {isTabletOrMobile ? `${el.slice(0, 4)}...${el.slice(-4)}` : el}
              </a>
            </div>
            <span style={{lineHeight: '21px'}}>{lastUpdated} UTC</span>
          </QuorumInfoWrap>
        </Row>
      ))}
    </Wrap>
  );
};
